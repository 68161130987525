import { InlineLoading } from "@carbon/react";
import { useEffect, useRef, useState } from "react";
import { Image, Layer, Stage } from "react-konva";
import useImage from "use-image";
import { AssetDiagramRectTag } from "../../../components/monitoring-dashboards/process-flow-diagrams/AssetDiagramRectTag";
import { DiagramDashboardDisplaySettingsDto } from "../../monitoring/dtos/dashboard-display-settings.dto";
import {
  TagValueData,
  TelemetryTagSettings,
} from "../../monitoring/telemetryApi";

interface AssetPfdDiagramProps {
  displaySettings: DiagramDashboardDisplaySettingsDto;
  tagSettings: TelemetryTagSettings;
  telemetryTagsData?: TagValueData[];
}

export const AssetPfdDiagram = ({
  displaySettings,
  tagSettings,
  telemetryTagsData,
}: AssetPfdDiagramProps) => {
  const [image] = useImage(displaySettings.diagramSettings.fileUrl);

  const [stageSize, setStageSize] = useState({
    width: 300,
    height: 0,
  });
  const parentRef = useRef<HTMLDivElement>(null);

  const [stage, setStage] = useState({
    scale: 1,
    x: 0,
    y: 0,
  });
  const [tagRadius, setTagRadius] = useState(0);

  useEffect(() => {
    setTagRadius(displaySettings.diagramSettings.tagRadius);
  }, [displaySettings]);

  useEffect(() => {
    const handleResize = () => {
      const parentWidth = parentRef.current?.clientWidth || 600;
      const parentHeight = parentRef.current?.clientHeight || 400;
      const aspectRatio = image ? image.width / image.height : 1;
      const containerAspectRatio = parentWidth / parentHeight;

      let newWidth = parentWidth;
      let newHeight = parentHeight;

      if (aspectRatio > containerAspectRatio) {
        newHeight = parentWidth / aspectRatio;
      } else {
        newWidth = parentHeight * aspectRatio;
      }

      setStageSize({ width: newWidth, height: newHeight });

      setStage({
        scale: 1,
        x: 0,
        y: 0,
      });
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [parentRef.current, image]);

  if (!image || !telemetryTagsData) {
    return <InlineLoading />;
  }
  const scale = stageSize.width / image.width;

  const handleWheel = (e: any) => {
    e.evt.preventDefault();

    const scaleBy = 1.02;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };

    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    setStage({
      scale: newScale,
      x: (stage.getPointerPosition().x / newScale - mousePointTo.x) * newScale,
      y: (stage.getPointerPosition().y / newScale - mousePointTo.y) * newScale,
    });
  };

  const handleClick = (e: any) => {
    e.evt.preventDefault();

    const stage = e.target.getStage();
    const mousePointTo = {
      x: stage.getPointerPosition().x,
      y: stage.getPointerPosition().y,
    };

    return mousePointTo;
  };

  const getTagByTagId = (tagId: string) => {
    const tag = telemetryTagsData.find((tag) => tag.tagId === tagId);
    return tag;
  };

  return (
    <div
      ref={parentRef}
      id="pid-diagram-container"
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Stage
        width={stageSize.width}
        height={stageSize.height}
        scaleX={stage.scale}
        scaleY={stage.scale}
        x={stage.x}
        y={stage.y}
        onWheel={handleWheel}
        draggable
        onClick={handleClick}
      >
        <Layer>
          <Image
            image={image}
            width={stageSize.width}
            height={stageSize.height}
          />
        </Layer>
        <Layer>
          {Object.entries(displaySettings.telemetryPoints).map(
            ([key, point]) => (
              <AssetDiagramRectTag
                key={key}
                tagKey={key}
                x={point.x * scale}
                y={point.y * scale}
                radius={tagRadius * scale}
                backgroundColor={
                  tagSettings?.defaultTagBackgroundColor || "black"
                }
                tagValue={getTagByTagId(key)?.tagValue || "N/A"}
              />
            )
          )}
        </Layer>
      </Stage>
    </div>
  );
};
