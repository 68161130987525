import { LatLngExpression } from "leaflet";
import { AppConfig } from "../appConfig";

export enum Region {
  AU = "ap-southeast",
  US = "us",
}

export const RegionCenterPointAU: LatLngExpression = [-25.2744, 133.3798];
export const RegionCenterPointUS: LatLngExpression = [39.8283, -98.5795];

export const getAppRegion = () => {
  switch (true) {
    case AppConfig.appRegion.includes(Region.US):
      return Region.US;
    case AppConfig.appRegion.includes(Region.AU):
      return Region.AU;
    default:
      return "dev";
  }
};

export const getRegionFlag = () => {
  switch (true) {
    case AppConfig.appRegion.includes(Region.US):
      return <span style={{ fontSize: "32px" }}>🇺🇸</span>;
    case AppConfig.appRegion.includes(Region.AU):
      return <span style={{ fontSize: "32px" }}>🇦🇺</span>;
    default:
      return <></>;
  }
};

export const isUsRegion = (): boolean => {
  return getAppRegion() === Region.US;
};
