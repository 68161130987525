import { CloseFilled, FitToScreen } from "@carbon/icons-react";
import { Button, ContentSwitcher, Switch } from "@carbon/react";
import { useEffect, useState } from "react";
import {
  TelemetryDashboardType,
  TelemetryDashboardTypeLabelMap,
} from "../../monitoring/enums/TelemetryDashboardType.enum";
import {
  AssetTelemetryDto,
  TelemetryDashboard,
  TelemetryTagsDataResponse,
} from "../../monitoring/telemetryApi";
import { AssetDto } from "../assetsApi";
import { AssetDashboardContent } from "./AssetDashboardContent";

import "./AssetDashboard.scss";

interface AssetDashboardProps {
  assetTelemetry: AssetTelemetryDto;
  assetTelemetryData: TelemetryTagsDataResponse | null;
  asset: AssetDto;
  dashboards: TelemetryDashboard[];
}

export const AssetDashboard = ({
  assetTelemetry,
  assetTelemetryData,
  asset,
  dashboards,
}: AssetDashboardProps) => {
  const [selectedType, setSelectedType] = useState<TelemetryDashboardType>(
    dashboards[0]?.type || TelemetryDashboardType.PROCESS_FLOW_DIAGRAM
  );
  const [isFullScreen, setFullScreen] = useState(false);
  const [filteredDashboards, setFilteredDashboards] = useState<
    TelemetryDashboard[]
  >([]);

  useEffect(() => {
    const filtered = dashboards.filter(
      (dashboard) => dashboard.type === selectedType
    );
    setFilteredDashboards(filtered);
  }, [selectedType]);

  const handleSwitchChange = (event: any) => {
    setSelectedType(event.name);
  };

  const renderSwitches = () => {
    const uniqueDashboardTypes = Array.from(
      new Set(dashboards.map((dashboard) => dashboard.type))
    );

    return uniqueDashboardTypes.map((type) => (
      <Switch
        key={type}
        name={type}
        style={{ minWidth: "50%" }}
        text={
          TelemetryDashboardTypeLabelMap[type] ||
          TelemetryDashboardTypeLabelMap[TelemetryDashboardType.UNKNOWN]
        }
      />
    ));
  };

  const handleFullScreen = () => {
    setFullScreen(!isFullScreen);
  };

  return (
    <div style={{ height: "100%" }}>
      {dashboards.length > 0 && (
        <ContentSwitcher
          onChange={(event) => handleSwitchChange(event)}
          selectedIndex={0}
          className="content-switcher"
          size={"md"}
        >
          {renderSwitches()}
        </ContentSwitcher>
      )}

      <div
        className={
          isFullScreen
            ? "fullscreen-dashboard-content"
            : "switcher-dashboard-content"
        }
        style={{ width: "100%", height: "100%", minHeight: "200px" }}
      >
        <div className="dashboard-full-screen-button">
          <Button onClick={handleFullScreen} kind="ghost">
            {isFullScreen ? <CloseFilled /> : <FitToScreen />}
          </Button>
        </div>

        <AssetDashboardContent
          assetTelemetry={assetTelemetry}
          assetTelemetryData={assetTelemetryData}
          asset={asset}
          dashboards={filteredDashboards}
        />
      </div>
    </div>
  );
};
