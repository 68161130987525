import { apiClient } from "../../../components/api/apiClient";
import { PartnerUserDto } from "../../users/usersApi";

export interface CreateCustomerUserDto {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  position: string;
}

export interface CreatePartnerCustomerDto {
  name: string;
  description: string;
  countryCode: string;
  city: string;
  streetAddress: string;
  postalCode: string;
  phoneNumber: string;
  primaryUser: CreateCustomerUserDto;
}

interface AddPartnerCustomerResponseDto {
  customerId: string;
}

export interface PartnerCustomerDto {
  id: string;
  name: string;
  description: string;
  logoUrl: string;
  address: {
    city: string;
    streetAddress: string;
    postalCode: string;
  };
  phoneNumber: string;
}

export interface GetPartnerCustomersResponseDto {
  customers: PartnerCustomerDto[];
  users: PartnerUserDto;
}

export interface AddPartnerCustomerUserResponseDto {
  userId: string;
}

export interface PartnerCustomerDetailsDto {
  users: PartnerUserDto[];
  customer: PartnerCustomerDto;
  primaryAdminUserId: string;
}

export interface UpdateCustomerPrimaryUserDto {
  userId: string;
}

export const customersApi = {
  async getPartnerCustomerUsers(customerId?: string): Promise<any[]> {
    const response = await apiClient.get(
      `/partner-customer/${customerId}/users`
    );
    return response.data;
  },

  async getPartnerCustomers(): Promise<GetPartnerCustomersResponseDto> {
    const response = await apiClient.get("/partner-customer");
    return response.data;
  },

  async getPartnerCustomerById(id: string): Promise<any> {
    const response = await apiClient.get(`/partner-customer/${id}`);
    return response.data;
  },

  async addPartnerCustomer(
    customer: CreatePartnerCustomerDto
  ): Promise<AddPartnerCustomerResponseDto> {
    const response = await apiClient.post("/partner-customer", customer);
    return response.data;
  },

  async updatePartnerPrimaryUser(id: string): Promise<any> {
    const response = await apiClient.post(
      `/partner-customer/${id}/update-primary-user`
    );
    return response.data;
  },

  async addPartnerCustomerUser(
    id: string,
    user: any
  ): Promise<AddPartnerCustomerUserResponseDto> {
    const response = await apiClient.post(
      `/partner-customer/${id}/users`,
      user
    );
    return response.data;
  },

  async deletePartnerCustomerUser(
    customerId: string,
    userId: string
  ): Promise<any> {
    const response = await apiClient.delete(
      `/partner-customer/${customerId}/users/${userId}`
    );
    return response.data;
  },

  async updateCustomerPrimaryUser(
    customerId: string,
    updatePrimaryUserdto: UpdateCustomerPrimaryUserDto
  ) {
    const response = await apiClient.post(
      `/partner-customer/${customerId}/update-primary-user`,
      updatePrimaryUserdto
    );

    return response.data;
  },
};
