import React from "react";

interface ScrollAreaProps {
  children: React.ReactNode;
  maxHeight?: string;
}

export const ScrollArea = ({
  children,
  maxHeight = "100%",
}: ScrollAreaProps) => {
  return (
    <div
      style={{
        overflowY: "auto",
        maxHeight,
        width: "100%",
        padding: "8px",
        boxSizing: "border-box",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
      className="hide-scrollbar"
    >
      {children}
    </div>
  );
};
