import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import "./index.scss";

import * as Sentry from "@sentry/react";
import { AppConfig } from "./appConfig";

Sentry.init({
  dsn: AppConfig.sentryDsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: AppConfig.appBranch,
});

const appRootElement = document.getElementById("root");

ReactDOM.createRoot(appRootElement!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
