import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { authApi } from "../routes/auth/authApi";

export default function RequireAuthRoutes({
  children,
}: {
  children: JSX.Element;
}) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userSession = authApi.getUserSession();

    if (userSession?.accessToken) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
      navigate("/login");
    }
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return children;
  }
}
