import dayjs from "dayjs";
import "dayjs/locale/en";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

export const formatDate = (dateInString: string): string => {
  return dayjs(dateInString).tz().format("DD MMMM YYYY");
};

export const formatToMonthYear = (dateInString: string): string => {
  return dayjs(dateInString).tz().format("MM/YYYY");
};

export const formatDateWithTime = (dateInString: string | number): string => {
  return dayjs(dateInString).tz().format("DD MMMM YYYY @ HH:mm");
};

export const getUtcNow = (): string => {
  return dayjs().toISOString();
};

export const getTimestampInSeconds = (): number => {
  return Math.floor(Date.now() / 1000);
};

export const formatToLocaleDateTime = (timestamp: any) => {
  if (!timestamp) {
    return "-";
  }

  const formattedDateTime =
    new Date(timestamp).toLocaleDateString("en-US", {
      month: "long",
      day: "2-digit",
      year: "numeric",
    }) +
    ", " +
    new Date(timestamp).toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  return formattedDateTime;
};

export const formatLocaleDate = (dateString: string, locale = "en") => {
  return dayjs(dateString).locale(locale).format("DD MMMM YYYY");
};

export const timeFromNow = (dateInString: string | number): string => {
  return dayjs(dateInString).fromNow();
};
