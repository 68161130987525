import { CloseFilled, FitToScreen } from "@carbon/icons-react";
import { Button, ContentSwitcher, Switch, Theme, Toggle } from "@carbon/react";
import { useEffect, useState } from "react";
import { AssetDto } from "../../assets/assetsApi";
import {
  TelemetryDashboardType,
  TelemetryDashboardTypeLabelMap,
} from "../../monitoring/enums/TelemetryDashboardType.enum";
import {
  AssetGroupTelemetryResponseDto,
  TelemetryDashboard,
} from "../assetGroupTelemetryApi";
import { AssetGroupTelemetryDataResponse } from "../dtos/asset-group-pfd.dto";
import "./AssetGroupDashboard.scss";
import { AssetGroupDashboardContent } from "./AssetGroupDashboardContent";
import { AssetGroupDashboardNavBar } from "./AssetGroupDashboardNavBar";

interface AssetGroupDashboardProps {
  assetGroupTelemetry: AssetGroupTelemetryResponseDto;
  assetGroupTelemetryData: AssetGroupTelemetryDataResponse | null;
  assets: AssetDto[];
  dashboards: TelemetryDashboard[];
}

export const AssetGroupDashboard = ({
  assetGroupTelemetry,
  assetGroupTelemetryData,
  assets,
  dashboards,
}: AssetGroupDashboardProps) => {
  const [selectedType, setSelectedType] = useState<TelemetryDashboardType>(
    dashboards[0]?.type || TelemetryDashboardType.PROCESS_FLOW_DIAGRAM
  );
  const [isFullScreen, setFullScreen] = useState(false);
  const [filteredDashboards, setFilteredDashboards] = useState<
    TelemetryDashboard[]
  >([]);
  const [isDarkMode, setIsDarkMode] = useState(true);

  useEffect(() => {
    const filtered = dashboards.filter(
      (dashboard) => dashboard.type === selectedType
    );
    setFilteredDashboards(filtered);
  }, [selectedType]);

  const handleSwitchChange = (event: any) => {
    setSelectedType(event.name);
  };

  const renderSwitches = () => {
    const uniqueDashboardTypes = Array.from(
      new Set(dashboards.map((dashboard) => dashboard.type))
    );

    return uniqueDashboardTypes.map((type) => (
      <Switch
        key={type}
        name={type}
        style={{ minWidth: "50%" }}
        text={
          TelemetryDashboardTypeLabelMap[type] ||
          TelemetryDashboardTypeLabelMap[TelemetryDashboardType.UNKNOWN]
        }
      />
    ));
  };

  const handleFullScreen = () => {
    setFullScreen(!isFullScreen);
  };

  return (
    <div style={{ height: "100%", width: "100%", overflow: "hidden" }}>
      {dashboards.length > 0 && (
        <ContentSwitcher
          onChange={(event) => handleSwitchChange(event)}
          selectedIndex={0}
          className="content-switcher"
          size={"md"}
        >
          {renderSwitches()}
        </ContentSwitcher>
      )}
      <Theme theme={isDarkMode ? "g100" : "g10"}>
        <div
          className={
            isFullScreen
              ? "group-fullscreen-dashboard-content"
              : "group-switcher-dashboard-content"
          }
          style={{
            width: "100%",
            height: "100%",
            minHeight: "200px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Theme theme={isDarkMode ? "g100" : "g10"} style={{ width: "100%" }}>
            <AssetGroupDashboardNavBar
              assetGroup={assetGroupTelemetry.assetGroup}
              assets={assetGroupTelemetry.assets}
              assetGroupTelemetryData={assetGroupTelemetryData}
            />
          </Theme>

          <div className="group-dashboard-full-screen-button ">
            <Toggle
              size="sm"
              id="dark-mode"
              labelA="Dark mode"
              labelB="Light mode"
              onToggle={() => setIsDarkMode(!isDarkMode)}
            />
            <Button onClick={handleFullScreen} kind="ghost">
              {isFullScreen ? <CloseFilled /> : <FitToScreen />}
            </Button>
          </div>

          <AssetGroupDashboardContent
            assetGroupTelemetry={assetGroupTelemetry.assetGroupTelemetry}
            assetGroupTelemetryDataResponse={assetGroupTelemetryData}
            dashboards={filteredDashboards}
            assets={assets}
            isDarkMode={isDarkMode}
          />
        </div>
      </Theme>
    </div>
  );
};
