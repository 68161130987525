import axios, { HttpStatusCode } from "axios";
import { AppConfig } from "../../appConfig";
import { authApi } from "../../routes/auth/authApi";

const TIMEOUT_MS = 30000;

const apiClient = axios.create({
  baseURL: AppConfig.apiBaseUrl,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: TIMEOUT_MS,
});

const IdTokenHeaderName = "X-Id-Token";

apiClient.interceptors.request.use(
  (config) => {
    if (authApi.isAuthenticated()) {
      const accessToken = authApi.getUserSession()?.accessToken;
      const idToken = authApi.getUserSession()?.idToken;
      config.headers.Authorization = `Bearer ${accessToken}`;
      config.headers[IdTokenHeaderName] = `${idToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let refreshingFunc: any = undefined;

apiClient.interceptors.response.use(
  (response) => response,
  async (error: any) => {
    const originalRequest = error.config;

    if (
      error.response.status === HttpStatusCode.Unauthorized &&
      !originalRequest._retry
    ) {
      if (!refreshingFunc) {
        const refreshTokenDto = {
          idToken: authApi.getUserSession().idToken,
          refreshToken: authApi.getUserSession().refreshToken,
        };
        refreshingFunc = authApi.refreshAccessToken(refreshTokenDto);
      }
      originalRequest._retry = true;

      await refreshingFunc;

      const accessToken = authApi.getUserSession().accessToken;
      const idToken = authApi.getUserSession().idToken;

      originalRequest.headers.Authorization = `Bearer ${accessToken}`;
      originalRequest.headers[IdTokenHeaderName] = `${idToken}`;

      try {
        return axios(originalRequest);
      } catch (error) {
        return error;
      } finally {
        refreshingFunc = undefined;
      }
    }

    if (error.response.status >= HttpStatusCode.BadRequest) {
      // const message = error?.response?.data?.message;
    }

    return Promise.reject(error);
  }
);

export { apiClient };
