import { Button } from "@carbon/react";
import { useNavigate } from "react-router-dom";
import { formatToLocaleDateTime } from "../../../common/dates";
import { AssetDto } from "../../assets/assetsApi";
import { AssetGroupDto } from "../assetGroupsApi";
import { AssetGroupTelemetryDataResponse } from "../dtos/asset-group-pfd.dto";

interface AssetGroupDashboardNavBarProps {
  assets: AssetDto[];
  assetGroup: AssetGroupDto;
  assetGroupTelemetryData: AssetGroupTelemetryDataResponse | null;
}

export const AssetGroupDashboardNavBar = ({
  assetGroup,
  assets,
  assetGroupTelemetryData,
}: AssetGroupDashboardNavBarProps) => {
  const navigate = useNavigate();

  const assetsTelemetryLastUpdatedAt = assets
    .map((asset) => {
      const telemetryData =
        assetGroupTelemetryData?.assetGroupTelemetryData.find(
          (data) => data.telemetryId === asset.telemetryId
        );

      return {
        assetName: asset.name,
        lastUpdateAt: telemetryData
          ? new Date(telemetryData.lastUpdateAt)
          : "-",
      };
    })
    .filter((item) => item.lastUpdateAt !== null);

  const sortedAssetsTelemetryLastUpdatedAt = assetsTelemetryLastUpdatedAt.sort(
    (a, b) => (a.assetName || "").localeCompare(b.assetName || "")
  );

  return (
    <div
      style={{
        display: "flex",
        padding: "10px",
        width: "100%",
        borderBottom: "1px solid lightgrey",
      }}
    >
      <div style={{ display: "flex", width: "calc(100% - 100px)" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              padding: "5px 10px",
              borderRadius: "5px",
              fontWeight: "bold",
            }}
          >
            {assetGroup.name}
          </div>

          <div
            style={{
              height: "30px",
              width: "1px",
              margin: "0 15px",
            }}
          ></div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              maxWidth: "calc(100% -",
            }}
          >
            {assets.map((asset) => (
              <Button
                key={asset.id}
                style={{ marginBottom: "10px" }}
                onClick={() =>
                  navigate(`/monitoring/${asset.id}/asset-details`)
                }
                kind="tertiary"
              >
                <span style={{ fontWeight: 600 }}>{asset.name}</span>
              </Button>
            ))}
          </div>
        </div>

        <div
          style={{
            padding: "10px",
            border: "1px solid #ddd",
            borderRadius: "5px",
            display: "flex",
            maxWidth: "200px",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <div style={{ fontSize: "10px" }}>Last Update at:</div>

          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            {sortedAssetsTelemetryLastUpdatedAt.length > 0 ? (
              sortedAssetsTelemetryLastUpdatedAt.map((item, index) => (
                <div
                  key={index}
                  style={{
                    fontSize: "9px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>{item.assetName}</span>
                  <span>{formatToLocaleDateTime(item.lastUpdateAt)}</span>
                </div>
              ))
            ) : (
              <div>-</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
