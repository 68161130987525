import {
  Column,
  Grid,
  ProgressIndicator,
  ProgressStep,
  Stack,
} from "@carbon/react";
import { useState } from "react";
import PageTitle from "../../common/PageTitle";
import { CustomerCreationSuccess } from "./CustomerCreationSuccessful";
import { NewCustomerConfirmation } from "./NewCustomerConfirmation";
import { NewCustomerForm } from "./NewCustomerForm";
import { NewCustomerUserForm } from "./NewCustomerUserForm";
import {
  CreateCustomerUserDto,
  CreatePartnerCustomerDto,
  customersApi,
} from "./api/customersApi";

export enum AddCustomerProgressStep {
  AddCustomer = 0,
  AddPrimaryContact = 1,
  Confirmation = 2,
  CreationSuccessful = 3,
}

const INITIAL_USER_FORM_DATA = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  position: "",
};

const INITIAL_FORM_DATA = {
  name: "",
  description: "",
  countryCode: "",
  phoneNumber: "",
  city: "",
  postalCode: "",
  streetAddress: "",
};

export const NewCustomerPage = () => {
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [primaryUserFormData, setPrimaryUserFormData] = useState(
    INITIAL_USER_FORM_DATA
  );
  const [currentStepIndex, setCurrentStepIndex] = useState(
    AddCustomerProgressStep.AddCustomer
  );
  const [formError, setFormError] = useState([]);

  const resetState = () => {
    setFormData(INITIAL_FORM_DATA);
    setPrimaryUserFormData(INITIAL_USER_FORM_DATA);
    setCurrentStepIndex(AddCustomerProgressStep.AddCustomer);
  };

  const handleSubmit = async () => {
    try {
      const response = await customersApi.addPartnerCustomer({
        ...trimFormData(formData),
        primaryUser: trimFormData(primaryUserFormData),
      });

      if (response.customerId) {
        setCurrentStepIndex(AddCustomerProgressStep.CreationSuccessful);
      }
      return response;
    } catch (e: any) {
      if (typeof e.response.data.message === "string") {
        setFormError([e.response.data.message] as any);
      } else if (Array.isArray(e.response.data.message)) {
        setFormError(e.response.data.message);
      } else {
        setFormError([]);
      }
      return e;
    }
  };

  const trimFormData = (formData: any) => {
    const trimmedFormData = { ...formData };

    for (const key in trimmedFormData) {
      trimmedFormData[key] = trimmedFormData[key].trim();
    }

    return trimmedFormData;
  };

  const handleAddCustomerProgress = (
    event: any,
    formData: CreatePartnerCustomerDto
  ) => {
    event.preventDefault();
    setFormData(formData);
    setCurrentStepIndex(AddCustomerProgressStep.AddPrimaryContact);
  };

  const handleUserFormProgress = (
    event: any,
    formData: CreateCustomerUserDto
  ) => {
    event.preventDefault();
    setPrimaryUserFormData(formData);
    setCurrentStepIndex(AddCustomerProgressStep.Confirmation);
  };

  const handleUserFormBack = () => {
    setCurrentStepIndex(AddCustomerProgressStep.AddCustomer);
  };

  const handleConfirmationBack = () => {
    setCurrentStepIndex(AddCustomerProgressStep.AddPrimaryContact);
  };

  const renderProgressView = (currentStepIndex: AddCustomerProgressStep) => {
    switch (currentStepIndex) {
      case AddCustomerProgressStep.AddCustomer:
        return (
          <NewCustomerForm
            initialValues={formData}
            handleProgress={handleAddCustomerProgress}
          />
        );
      case AddCustomerProgressStep.AddPrimaryContact:
        return (
          <NewCustomerUserForm
            initialValues={primaryUserFormData}
            handleProgress={handleUserFormProgress}
            handleBack={handleUserFormBack}
            formTitle={"Add Customer's Primary Contact"}
          />
        );
      case AddCustomerProgressStep.Confirmation:
        return (
          <NewCustomerConfirmation
            formData={{ ...formData, primaryUser: primaryUserFormData }}
            handleSubmit={handleSubmit}
            handleBack={handleConfirmationBack}
            formError={formError}
          />
        );
      case AddCustomerProgressStep.CreationSuccessful:
        return <CustomerCreationSuccess resetState={resetState} />;
      default:
        return <></>;
    }
  };

  return (
    <Stack>
      <PageTitle pageTitle="Add customer" />
      <Stack as="div" className="add-user-page">
        <Grid className="add-user-page-grid">
          <Column md={2} lg={4} as="div" className="user-progress-indicator">
            <ProgressIndicator
              vertical
              currentIndex={currentStepIndex}
              spaceEqually
            >
              <ProgressStep
                label="Step 1"
                secondaryLabel="Add Customer"
                description="Provide essential customer information"
              />
              <ProgressStep
                label="Step 2"
                secondaryLabel="Add Customer's Primary Contact"
                description="Provide primary contact’s essential information."
              />
              <ProgressStep
                label="Step 3"
                secondaryLabel="Confirm Information"
                description="Review your details"
              />
            </ProgressIndicator>
          </Column>
          <Column md={6} lg={12} as="div">
            {renderProgressView(currentStepIndex)}
          </Column>
        </Grid>
      </Stack>
    </Stack>
  );
};
