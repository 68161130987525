import { TagValueData } from "../../monitoring/telemetryApi";

interface DashboardSubGridCardProps {
  metrics: MetricProps[];
  title?: string;
  getTagByTagId: (tag: string) => TagValueData | undefined;
  colSpan: number;
  rowSpan: number;
}

interface MetricProps {
  title: string;
  unit: string;
  tag: string;
}

export const DashboardSubGridCard = ({
  metrics,
  title,
  getTagByTagId,
  colSpan,
}: DashboardSubGridCardProps) => {
  return (
    <div
      style={{
        height: "calc(100% + 8px)",
        background: "rgb(25, 24, 24)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
      {title && (
        <div style={{ padding: "0.5rem", color: "white" }}>{title}</div>
      )}

      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${colSpan}, 1fr)`,
          height: "100%",
          gap: "0",
        }}
      >
        {metrics.map((metric, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ fontSize: "0.75rem", color: "#B0BEC5" }}>
              {metric.title}
            </span>

            <div style={{ display: "flex", alignItems: "flex-end", gap: "0" }}>
              <span
                style={{
                  padding: "0 0.5rem",
                  fontSize: "1.25rem",
                  color: "white",
                }}
              >
                {getTagByTagId(metric.tag)
                  ? getTagByTagId(metric.tag)?.tagValue
                  : "-"}
              </span>
              <span style={{ color: "white" }}>{metric.unit}</span>
            </div>

            <span style={{ fontSize: "0.75rem", color: "#B0BEC5" }}>
              {metric.tag}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
