import { Route, Routes } from "react-router";
import { AssetGroupDetailsPage } from "../asset-groups/AssetGroupDetailsPage";
import { AssetDetailsPage } from "../assets/AssetDetailsPage";
import { MonitoringHomePage } from "./MonitoringHomePage";

export const MonitoringNavigator = () => {
  return (
    <Routes>
      <Route index element={<MonitoringHomePage />} />
      <Route path="card" element={<MonitoringHomePage />} />
      <Route path="map" element={<MonitoringHomePage />} />
      <Route path=":assetId/asset-details" element={<AssetDetailsPage />} />
      <Route
        path="asset-group/:assetGroupId/details"
        element={<AssetGroupDetailsPage />}
      />
    </Routes>
  );
};
