import { AddFilled } from "@carbon/icons-react";
import { Button, Stack } from "@carbon/react";
import { useNavigate } from "react-router";
import PageTitle from "../../common/PageTitle";
import { CustomerList } from "./CustomerList";
import "./customerHomePage.scss";

export const CustomerHomePage = () => {
  const navigate = useNavigate();

  return (
    <Stack>
      <PageTitle pageTitle={"Customers"} />
      <Stack className="customer-button-group">
        <Button
          kind="tertiary"
          renderIcon={AddFilled}
          onClick={() => navigate("new-customer")}
        >
          Add new customer
        </Button>
      </Stack>
      <CustomerList />
    </Stack>
  );
};
