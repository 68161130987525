import { Button, Loading, Toggle } from "@carbon/react";
import React, { useEffect, useState } from "react";
import { AssetDto } from "../../assets/assetsApi";
import { DiagramDashboardDisplaySettingsDto } from "../../monitoring/dtos/dashboard-display-settings.dto";
import {
  AssetTelemetryDto,
  TelemetryDashboard,
  TelemetryTagSettings,
  TelemetryTagsDataResponse,
} from "../../monitoring/telemetryApi";
import { AssetPidDiagram } from "./AssetPidDiagram";
import { AssetPidDiagramDashboardSidebar } from "./AssetPidDiagramDashboardSidebar";

interface AssetPidDiagramDashboardProps {
  assetTelemetry: AssetTelemetryDto;
  assetTelemetryData: TelemetryTagsDataResponse;
  asset: AssetDto;
  dashboards: TelemetryDashboard[];
}

export const POLLING_INTERVAL_MS = 5000;

export const AssetPidDiagramDashboard = ({
  assetTelemetryData,
  asset,
  dashboards,
}: AssetPidDiagramDashboardProps) => {
  const [telemetryTagsSettings, setTelemetryTagSettings] =
    useState<TelemetryTagSettings>();
  const [activeDashboardIndex, setActiveDashboardIndex] = useState(0);
  const [showAllTags, setShowAllTags] = useState(true);

  useEffect(() => {
    setupDiagramSettings();
  }, [dashboards, activeDashboardIndex]);

  const setupDiagramSettings = () => {
    if (dashboards && dashboards[activeDashboardIndex]) {
      setTelemetryTagSettings(
        dashboards[activeDashboardIndex].displaySettings.telemetryTagSettings
      );
    }
  };

  const sortedDashboards = dashboards.sort((a, b) =>
    a.displayName.localeCompare(b.displayName)
  );

  const renderTopBarButtonGroup = () => (
    <div
      style={{
        display: "flex",
        gap: "8px",
        alignItems: "center",
        width: "calc(100% - 200px)",
        justifyContent: "space-between",
      }}
    >
      <div>
        {sortedDashboards.map((dashboard, index) => (
          <React.Fragment key={dashboard.id}>
            <Button
              onClick={() => setActiveDashboardIndex(index)}
              style={{
                backgroundColor:
                  index === activeDashboardIndex ? "#0f62fe" : "#e0e0e0",
                color: index === activeDashboardIndex ? "#ffffff" : "#000000",
                marginRight: "8px",
              }}
            >
              {dashboard.displayName}
            </Button>
          </React.Fragment>
        ))}
      </div>

      <Toggle
        labelB={showAllTags ? "Hide all tags" : "Show all tags"}
        labelA={showAllTags ? "Hide all tags" : "Show all tags"}
        toggled={showAllTags}
        onToggle={() => setShowAllTags(!showAllTags)}
        id={"show-all-tags-toggle"}
        size="sm"
      />
    </div>
  );

  const renderFullScreenTopBar = () => (
    <div
      style={{
        display: "flex",
        padding: "8px",
        maxWidth: "100%",
        borderBottom: "1px solid lightGrey",
        marginBottom: "8px",
        justifyContent: "flex-start",
      }}
    >
      <div
        style={{
          borderRight: "1px solid lightGrey",
          padding: "12px",
          marginRight: "12px",
        }}
      >
        {asset.name}
      </div>

      {renderTopBarButtonGroup()}
    </div>
  );

  const renderContent = () => {
    if (!dashboards || !assetTelemetryData) {
      return <LoadingContainer />;
    }

    return (
      <div style={{ display: "flex", height: "100%" }}>
        <div
          style={{
            width: "300px",
            maxHeight: "calc(100vh - 120px)",
            overflowY: "auto",
          }}
        >
          <AssetPidDiagramDashboardSidebar
            telemetryTagsData={assetTelemetryData}
            dashboards={dashboards}
          />
        </div>

        <div
          style={{
            display: "flex",
            width: "calc(100% - 300px)",
            justifyContent: "center",
          }}
        >
          <AssetPidDiagram
            displaySettings={
              dashboards[activeDashboardIndex]
                .displaySettings as DiagramDashboardDisplaySettingsDto
            }
            tagSettings={telemetryTagsSettings!}
            telemetryTagsData={assetTelemetryData.tags}
            showAllTags={showAllTags}
          />
        </div>
      </div>
    );
  };

  const LoadingContainer = () => (
    <div
      style={{
        width: "100%",
        height: "300px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loading withOverlay={false} />
    </div>
  );

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {renderFullScreenTopBar()}
      {renderContent()}
    </div>
  );
};
