import { Button } from "@carbon/react";
import { useEffect, useRef, useState } from "react";
import { Marker, Popup, useMap } from "react-leaflet";
import { useNavigate } from "react-router";
import { AssetDto, AssetSiteDto, AssetTypeDto } from "../assets/assetsApi";
import "./assetMarker.scss";

interface AssetMarkerProps {
  site: AssetSiteDto;
  assets: AssetDto[];
  itemTypes: AssetTypeDto[];
}

export const AssetMarker = ({ site, assets, itemTypes }: AssetMarkerProps) => {
  const navigate = useNavigate();
  const [refReady, setRefReady] = useState<boolean>(false);
  const popupRef = useRef<any>(null);
  const map = useMap();
  const [showStatus, setShowStatus] = useState<boolean>(false);

  useEffect(() => {
    if (refReady) {
      map.openPopup(popupRef.current);
    }
  }, [refReady, map]);

  const getItemType = (assetId: string) => {
    const asset = assets.find((asset) => asset.id === assetId);
    return asset
      ? itemTypes.find((type) => type.id === asset.assetTypeId)
      : null;
  };

  return (
    <div onClick={() => setShowStatus(!showStatus)}>
      <Marker
        position={[site.geoLocation.longitude, site.geoLocation.latitude]}
      >
        <Popup
          ref={(r: any) => {
            popupRef.current = r;
            setRefReady(true);
          }}
          className="custom-popup"
          closeButton={false}
          autoClose={false}
          closeOnClick={false}
          closeOnEscapeKey={false}
        >
          <div className="cds--type-heading-02 popup-site-name">
            {site.displayName}
            <div className="cds--type-regular">{site.fullAddress}</div>
          </div>

          <div className="popup-asset-list">
            {assets.length === 0 ? (
              <div>No assets available.</div>
            ) : (
              assets.map((asset) => (
                <div key={asset.id} className="marker-asset-details">
                  <div className="asset-details">
                    <span className="cds--type-heading-01">
                      {asset.name} -{" "}
                    </span>
                    <span>{getItemType(asset.id)?.name}</span>
                    <div className="cds--type-helper-text-01">
                      Part of{" "}
                      {asset.assetGroups.map((group) => group.name).join(", ")}
                    </div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      kind="primary"
                      size="sm"
                      onClick={() =>
                        navigate(`/monitoring/${asset.id}/asset-details`, {
                          state: { assetId: asset.id },
                        })
                      }
                    >
                      More Details
                    </Button>
                  </div>
                </div>
              ))
            )}
          </div>
        </Popup>
      </Marker>
    </div>
  );
};
