import { Breadcrumb, BreadcrumbItem, InlineLoading } from "@carbon/react";
import "./scss/breadcrumb.scss";

interface BreadcrumbItemData {
  label: string;
  href?: string;
  showLoading?: boolean;
  isCurrentPage?: boolean;
}

export interface CustomBreadcrumbProps {
  items: BreadcrumbItemData[];
}

export const CustomBreadcrumb = ({ items }: CustomBreadcrumbProps) => {
  return (
    <Breadcrumb className="page-breadcrumb" noTrailingSlash>
      {items.map((item, index) => (
        <BreadcrumbItem
          key={index}
          href={item.href}
          isCurrentPage={item.isCurrentPage}
        >
          {item.showLoading ? <InlineLoading /> : item.label}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};
