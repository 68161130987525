import { CheckmarkFilled } from "@carbon/icons-react";
import { Button, ButtonSet } from "@carbon/react";
import { useNavigate } from "react-router";
import "../../common/scss/form.scss";

export const CustomerCreationSuccess = ({
  resetState,
}: {
  resetState: () => void;
}) => {
  const navigate = useNavigate();

  return (
    <div className="user-creation-success">
      <CheckmarkFilled size="40" color="#24A148" />
      <p className="cds--type-fluid-heading-04">Customer added successfully</p>
      <p className="cds--type-fluid-heading-03">
        We have emailed the user all the details the need to get started.
      </p>
      <ButtonSet className="form-button-group">
        <Button kind="tertiary" onClick={resetState}>
          Add another customer
        </Button>
        <Button kind="tertiary" onClick={() => navigate("/customers")}>
          View Customers
        </Button>
      </ButtonSet>
    </div>
  );
};
