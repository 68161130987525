import {
  Column,
  Grid,
  ProgressIndicator,
  ProgressStep,
  Stack,
} from "@carbon/react";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { CustomBreadcrumb } from "../../common/CustomBreadcrumb";
import { CustomerUserCreationSuccessful } from "./CustomerUserCreationSuccessful";
import { NewCustomerUserConfirmation } from "./NewCustomerUserConfirmation";
import { NewCustomerUserForm } from "./NewCustomerUserForm";
import { customersApi } from "./api/customersApi";

export enum AddCustomerUserProgressStep {
  AddCustomerUser = 0,
  Confirmation = 1,
  CreationSuccessful = 2,
}

const INITIAL_FORM_DATA = {
  firstName: "",
  lastName: "",
  position: "",
  phoneNumber: "",
  email: "",
};

export const NewCustomerUserPage = () => {
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [currentStepIndex, setCurrentStepIndex] = useState(
    AddCustomerUserProgressStep.AddCustomerUser
  );
  const [formErrors, setFormErrors] = useState([]);
  const { customerId } = useParams();
  const location = useLocation();
  const customerName = location.state?.customerName;
  const navigate = useNavigate();

  if (!customerId || !customerName) {
    return null;
  }

  const resetState = () => {
    setFormData(INITIAL_FORM_DATA);
    setCurrentStepIndex(AddCustomerUserProgressStep.AddCustomerUser);
  };

  const handleAddCustomerProgress = (event: any, formData: any) => {
    event.preventDefault();
    const form = event.target.form;
    if (form && !form.checkValidity()) {
      form.reportValidity();
      return;
    }
    setFormData(formData);
    setCurrentStepIndex(AddCustomerUserProgressStep.Confirmation);
  };

  const handleSubmit = async () => {
    try {
      const response = await customersApi.addPartnerCustomerUser(
        customerId,
        formData
      );

      if (response.userId) {
        setCurrentStepIndex(AddCustomerUserProgressStep.CreationSuccessful);
      }

      return response;
    } catch (e: any) {
      if (typeof e.response.data.message === "string") {
        setFormErrors([e.response.data.message] as any);
      } else if (Array.isArray(e.response.data.message)) {
        setFormErrors(e.response.data.message);
      } else {
        setFormErrors([]);
      }
      return e;
    }
  };

  const handleUserFormBack = () => {
    navigate(-1);
  };

  const handleConfirmationBack = () => {
    setCurrentStepIndex(AddCustomerUserProgressStep.AddCustomerUser);
  };

  const renderProgressView = (
    currentStepIndex: AddCustomerUserProgressStep
  ) => {
    switch (currentStepIndex) {
      case AddCustomerUserProgressStep.AddCustomerUser:
        return (
          <NewCustomerUserForm
            initialValues={formData}
            handleProgress={handleAddCustomerProgress}
            formTitle={`Add new user for ${customerName}`}
            handleBack={handleUserFormBack}
          />
        );
      case AddCustomerUserProgressStep.Confirmation:
        return (
          <NewCustomerUserConfirmation
            formData={formData}
            handleSubmit={handleSubmit}
            handleBack={handleConfirmationBack}
            formErrors={formErrors}
          />
        );
      case AddCustomerUserProgressStep.CreationSuccessful:
        return (
          <CustomerUserCreationSuccessful
            resetState={resetState}
            customerId={customerId}
          />
        );
      default:
        return <></>;
    }
  };

  const breadcrumbItems = [
    { label: "Customers", href: "/customers" },
    {
      label: customerName ? customerName : "Loading...",
      href: `/customers/${customerId}/details`,
      showLoading: !customerName,
    },
    {
      label: "Add User",
      showLoading: !customerName,
      isCurrentPage: true,
    },
  ];

  return (
    <Stack>
      <CustomBreadcrumb items={breadcrumbItems} />

      <Stack as="div" className="add-user-page">
        <Grid className="add-user-page-grid">
          <Column md={2} lg={4} as="div" className="user-progress-indicator">
            <ProgressIndicator
              vertical
              currentIndex={currentStepIndex}
              spaceEqually
            >
              <ProgressStep
                label="Step 1"
                secondaryLabel="Add Customer User"
                description="Provide essential customer information"
              />
              <ProgressStep
                label="Step 2"
                secondaryLabel="Confirm Information"
                description="Review your details"
              />
            </ProgressIndicator>
          </Column>
          <Column md={6} lg={12} as="div">
            {renderProgressView(currentStepIndex)}
          </Column>
        </Grid>
      </Stack>
    </Stack>
  );
};
