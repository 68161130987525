import {
  Button,
  ButtonSet,
  Column,
  Form,
  FormLabel,
  Grid,
  Select,
  SelectItem,
  Stack,
  TextInput,
} from "@carbon/react";
import React, { useEffect, useState } from "react";
import { useRoleStore } from "./roleStore";
import { RoleDto, usersApi } from "./usersApi";

interface AddUserFormProps {
  initialValues: any;
  handleProgress: any;
  handleBack: any;
  formTitle?: string;
}

export const AddUserForm = ({
  initialValues,
  handleProgress,
  handleBack,
}: AddUserFormProps) => {
  const [availableRoles, setAvailableRoles] = useState<RoleDto[]>();
  const [formData, setFormData] = useState(initialValues);
  const [validationState, setValidationState] = useState({
    firstName: true,
    lastName: true,
    email: true,
    phoneNumber: true,
    position: true,
    roleId: true,
  });
  const setRoles = useRoleStore((state) => state.setRoles);

  useEffect(() => {
    getRoles();
  }, []);

  const handleChange = (event: any) => {
    const { id, value } = event.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const getRoles = async () => {
    const roles = await usersApi.getPartnerRoles();
    setRoles(roles);
    setAvailableRoles(roles);
  };

  const handleBlur = (
    event:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLSelectElement>
  ) => {
    const { id, value } = event.target;
    switch (id) {
      case "firstName":
        setInputFieldValid("firstName", value);
        break;
      case "lastName":
        setInputFieldValid("lastName", value);
        break;
      case "email":
        setInputFieldValid("email", value);
        break;
      case "phoneNumber":
        setPhoneValid(event);
        break;
      case "position":
        setInputFieldValid("position", value);
        break;
      case "roleId":
        setInputFieldValid("roleId", value);
        break;
      default:
        break;
    }
  };

  const setInputFieldValid = (inputId: any, value: any) => {
    setValidationState((prevState) => ({ ...prevState, [inputId]: !!value }));
  };

  const setPhoneValid = (event: any) => {
    setValidationState((prevState) => ({
      ...prevState,
      phoneNumber: /^\+61\d{9}$/.test(event.target.value),
    }));
  };

  return (
    <Form className="new-form" onSubmit={(e) => handleProgress(e, formData)}>
      <Stack>
        <Stack orientation="horizontal">
          <Stack>
            <div
              className="text-sans"
              dir="auto"
              style={{
                fontWeight: 400,
                padding: 0,
                margin: 0,
              }}
            >
              Add new user <br></br>
              <FormLabel>Provide essential user information</FormLabel>
            </div>
          </Stack>
        </Stack>

        <Grid>
          <Column md={3} lg={6}>
            <TextInput
              type="text"
              id={"firstName"}
              labelText={"First name"}
              value={formData.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-input"
              invalid={!validationState.firstName}
              invalidText={"This field is required"}
              required
            />
          </Column>
          <Column md={3} lg={6}>
            <TextInput
              type="text"
              id={"lastName"}
              labelText={"Last name"}
              value={formData.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-input"
              required
              invalid={!validationState.lastName}
              invalidText={"This field is required"}
            />
          </Column>
          <Column md={8} lg={16}>
            <TextInput
              type="email"
              id={"email"}
              required
              labelText={"Email"}
              value={formData.email}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-input"
              invalid={!validationState.email}
              invalidText={"Invalid email format or used email."}
            />
          </Column>
          <Column md={3} lg={6}>
            <TextInput
              type="text"
              id={"position"}
              required
              labelText={"Position"}
              value={formData.position}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-input"
              invalid={!validationState.position}
              invalidText={"This field is required"}
            />
          </Column>
          <Column md={3} lg={6}>
            <TextInput
              type="text"
              id={"phoneNumber"}
              required
              labelText={"Phone Number ( +61 )"}
              value={formData.phoneNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-input"
              pattern="^\+61\d{9}$"
              invalid={!validationState.phoneNumber}
              invalidText={
                !validationState.phoneNumber
                  ? "Phone number must be in the format +61xxxxxxxxx."
                  : "This field is required"
              }
            />
          </Column>
          <Column md={3} lg={6}>
            <Select
              id={"roleId"}
              labelText="Choose role"
              required
              value={formData.roleId}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-input"
              invalid={!validationState.roleId}
              invalidText={"This field is required"}
            >
              <SelectItem value="" text="" />
              {availableRoles &&
                availableRoles.map((role) => (
                  <SelectItem
                    key={role.id}
                    value={role.id}
                    text={role.displayName}
                  />
                ))}
            </Select>
          </Column>
        </Grid>
        <ButtonSet className="form-button-group">
          <Button type="submit">Next</Button>
          <Button kind="danger--tertiary" onClick={handleBack}>
            Back
          </Button>
        </ButtonSet>
      </Stack>
    </Form>
  );
};
