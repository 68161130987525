import { Route, Routes } from "react-router";
import { CustomerDetailsPage } from "./CustomerDetailsPage";
import { CustomerHomePage } from "./CustomerHomePage";
import { NewCustomerPage } from "./NewCustomerPage";
import { NewCustomerUserPage } from "./NewCustomerUserPage";

export const CustomerNavigator = () => {
  return (
    <Routes>
      <Route index element={<CustomerHomePage />} />
      <Route path="new-customer" element={<NewCustomerPage />} />
      <Route path=":customerId/details" element={<CustomerDetailsPage />} />
      <Route
        path=":customerId/new-customer-user"
        element={<NewCustomerUserPage />}
      />
    </Routes>
  );
};
