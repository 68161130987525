import { InlineLoading } from "@carbon/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { Image, Layer, Stage } from "react-konva";
import useImage from "use-image";
import { DiagramDashboardDisplaySettingsDto } from "../../monitoring/dtos/dashboard-display-settings.dto";
import {
  TagValueData,
  TelemetryTagSettings,
} from "../../monitoring/telemetryApi";
import { DiagramCircleTag } from "./DiagramCircleTag";
import { DiagramTagToolTip } from "./DiagramTagToolTip";

interface AssetPidDiagramProps {
  displaySettings: DiagramDashboardDisplaySettingsDto;
  tagSettings: TelemetryTagSettings;
  telemetryTagsData?: TagValueData[];
  showAllTags?: boolean;
}

export const AssetPidDiagram = ({
  displaySettings,
  tagSettings,
  telemetryTagsData,
  showAllTags = true,
}: AssetPidDiagramProps) => {
  const [image] = useImage(displaySettings.diagramSettings.fileUrl);
  const [stageSize, setStageSize] = useState({
    width: 300,
    height: 0,
  });
  const [showTooltips, setShowTooltips] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [stage, setStage] = useState({
    scale: 1,
    x: 0,
    y: 0,
  });
  const [tagRadius, setTagRadius] = useState(0);
  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const initialTooltips: { [key: string]: boolean } = {};

    Object.keys(displaySettings.telemetryPoints).forEach((key) => {
      initialTooltips[key] = showAllTags;
    });
    setShowTooltips(initialTooltips);

    setTagRadius(displaySettings.diagramSettings.tagRadius);
  }, [displaySettings, showAllTags]);

  useEffect(() => {
    const handleResize = () => {
      const parentWidth = parentRef.current?.clientWidth || 600;
      const parentHeight = parentRef.current?.clientHeight || 400;
      const aspectRatio = image ? image.width / image.height : 1;
      const containerAspectRatio = parentWidth / parentHeight;

      let newWidth = parentWidth;
      let newHeight = parentHeight;

      if (aspectRatio > containerAspectRatio) {
        newHeight = parentWidth / aspectRatio;
      } else {
        newWidth = parentHeight * aspectRatio;
      }

      setStageSize({ width: newWidth, height: newHeight });

      setStage({
        scale: 1,
        x: 0,
        y: 0,
      });
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [image]);

  if (!image || !telemetryTagsData) {
    return <InlineLoading />;
  }

  const scale = stageSize.width / image.width;

  const handleCircleClick = (key: string) => {
    const clickedTag = displaySettings.telemetryPoints[key];
    if (!clickedTag) return;

    const clickedX = clickedTag.x;
    const clickedY = clickedTag.y;

    const newTooltipsState: { [key: string]: boolean } = {};

    Object.keys(displaySettings.telemetryPoints).forEach((tagKey) => {
      const tagPoint = displaySettings.telemetryPoints[tagKey];

      if (tagPoint.x === clickedX && tagPoint.y === clickedY) {
        const currentState = showTooltips[key];
        newTooltipsState[tagKey] = !currentState;
      } else {
        newTooltipsState[tagKey] = showTooltips[tagKey];
      }
    });

    setShowTooltips(newTooltipsState);
  };

  const handleWheel = (e: any) => {
    e.evt.preventDefault();

    const scaleBy = 1.02;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };

    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    setStage({
      scale: newScale,
      x: (stage.getPointerPosition().x / newScale - mousePointTo.x) * newScale,
      y: (stage.getPointerPosition().y / newScale - mousePointTo.y) * newScale,
    });
  };

  const getTagByTagId = (tagId: string) => {
    const tag = telemetryTagsData.find((tag) => tag.tagId === tagId);
    return tag;
  };

  const findRelatedTags = (key: string) => {
    const basePoint = displaySettings.telemetryPoints[key];
    if (!basePoint) return [];

    return telemetryTagsData.filter((tag) => {
      const tagPoint = displaySettings.telemetryPoints[tag.tagId];
      return (
        tag.tagId.startsWith(key) ||
        (tagPoint && tagPoint.x === basePoint.x && tagPoint.y === basePoint.y)
      );
    });
  };

  return (
    <div
      ref={parentRef}
      id="pid-diagram-container"
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Stage
        width={stageSize.width}
        height={stageSize.height}
        scaleX={stage.scale}
        scaleY={stage.scale}
        x={stage.x}
        y={stage.y}
        onWheel={handleWheel}
        draggable
      >
        <Layer>
          <Image
            image={image}
            width={stageSize.width}
            height={stageSize.height}
          />
        </Layer>
        <Layer>
          {Object.entries(displaySettings.telemetryPoints).map(
            ([key, point], index) => (
              <DiagramCircleTag
                key={`${key}-${index}`}
                tagKey={key}
                tagValue={getTagByTagId(key)?.tagValue || "N/A"}
                x={point.x * scale}
                y={point.y * scale}
                radius={tagRadius * scale}
                backgroundColor={
                  tagSettings?.defaultTagBackgroundColor || "darkblue"
                }
                onClick={() => handleCircleClick(key)}
              />
            )
          )}
        </Layer>
        <Layer>
          {Object.entries(displaySettings.telemetryPoints).map(
            ([key, point], index) => (
              <Fragment key={`${key}-${index}`}>
                {showTooltips[key] &&
                  findRelatedTags(key).map((tag, index) => (
                    <DiagramTagToolTip
                      key={`${tag.id}-${index}`}
                      tagId={tag.tagId}
                      tagValue={`${
                        Number.isInteger(parseFloat(tag.tagValue))
                          ? tag.tagValue
                          : parseFloat(tag.tagValue).toFixed(4)
                      }`}
                      point={{
                        x: point.x * scale,
                        y:
                          (point.y - tagRadius * 3.1) * scale -
                          index * tagRadius * 2.1 * scale,
                      }}
                      tagRadius={tagRadius * scale}
                      defaultTagValueBackgroundColor={
                        tagSettings?.defaultTagValueBackgroundColor ||
                        "darkblue"
                      }
                      defaultTextColor={
                        tagSettings?.defaultTextColor || "white"
                      }
                    />
                  ))}
              </Fragment>
            )
          )}
        </Layer>
      </Stage>
    </div>
  );
};
