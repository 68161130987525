import PageTitle from "../../common/PageTitle";
import { UsersList } from "./UsersList";

export const UsersPage = () => {
  return (
    <div>
      <PageTitle pageTitle={"Users"} />
      <UsersList />
    </div>
  );
};
