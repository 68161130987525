import {
  SideNav,
  SideNavItems,
  SideNavLink,
  SwitcherDivider,
} from "@carbon/react";
import { useLocation, useNavigate } from "react-router";
import { NavbarHeader } from "./NavbarHeader";
import { PartnerLogo } from "./PartnerLogo";
import "./navbar.scss";

export function AppNavbar() {
  const menuItems = [
    { label: "Dashboard", to: "/dashboard", showDivider: false },
    { label: "Monitoring", to: "/monitoring", showDivider: true },
    { label: "Fleet Management", to: "/fleet-management", showDivider: false },
    { label: "Reports", to: "/report", showDivider: false },
    { label: "Customers", to: "customers", showDivider: true },
    { label: "Users", to: "/users", showDivider: false },
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const renderMenuItems = () => {
    return menuItems.map((menuItem, index) => (
      <div key={index} style={{ width: "100%" }}>
        {menuItem.showDivider && (
          <SwitcherDivider key={index} className="nav-divider" />
        )}
        <SideNavLink
          className="menu-item"
          onClick={(e: any) => {
            e.preventDefault();
            navigate(`${menuItem.to}`);
          }}
          isActive={location.pathname.includes(menuItem.to)}
        >
          {menuItem.label}
        </SideNavLink>
      </div>
    ));
  };

  return (
    <>
      <NavbarHeader />
      <SideNav
        isFixedNav
        expanded={true}
        isChildOfHeader={false}
        aria-label="Side navigation"
        className="navbar"
      >
        <div className="navbar-logo-container">
          <PartnerLogo />
        </div>
        <SideNavItems className="menu-container">
          {renderMenuItems()}
        </SideNavItems>
      </SideNav>
    </>
  );
}
