export enum Environment {
  DEV = "dev",
  PROD = "prod",
}

export interface AppConfig {
  env?: Environment;
  appVersion: string;
  appBranch: string;
  appRegion: string;
  cookieKey: string;
  apiBaseUrl: string;
  authRedirectUrl: string;
  authLogoutUrl: string;
  telemetryUrl: string;
  telemetryProductionUrl: string;
  sentryDsn: string;
  sentryAuthToken: string;
}

export const AppConfig: AppConfig = {
  env: Environment.DEV,
  appVersion: (import.meta as any).env.VITE_APP_VERSION,
  appBranch: (import.meta as any).env.VITE_BRANCH || "local",
  appRegion:
    (import.meta as any).env.VITE_AWS_FRONTEND_BUCKET_REGION || "us-east-2",
  cookieKey: "protium_data",
  telemetryProductionUrl: "https://telemetry-production.oneh2.io",
  telemetryUrl:
    (import.meta as any).env.VITE_TELEMETRY_URL ||
    "https://telemetry-dev.oneh2.io",
  authRedirectUrl:
    (import.meta as any).env.VITE_AUTH_REDIRECT_URL ||
    "https://protium-auth-dev.auth.us-east-2.amazoncognito.com/login?client_id=6d9kdtqule2a83hq1rbcb8mu5t&response_type=code&scope=email+openid+phone+profile&redirect_uri=http%3A%2F%2Flocalhost%3A5173%2Flogin",
  authLogoutUrl:
    (import.meta as any).env.VITE_AUTH_LOGOUT_URL ||
    "https://protium-auth-dev.auth.us-east-2.amazoncognito.com/logout?client_id=6d9kdtqule2a83hq1rbcb8mu5t&logout_uri=http%3A%2F%2Flocalhost%3A5173%2Flogin",
  apiBaseUrl:
    (import.meta as any).env.VITE_API_BASE_URL ||
    "https://protium-api-dev.oneh2.io",
  sentryDsn: (import.meta as any).env.VITE_SENTRY_DSN || "",
  sentryAuthToken: (import.meta as any).env.VITE_SENTRY_AUTH_TOKEN || "",
};
