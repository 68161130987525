import { Button, InlineLoading, Stack, Tag } from "@carbon/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { formatToLocaleDateTime } from "../../common/dates";
import { getTagUnit } from "../../common/telemetryUtils";
import {
  AssetTelemetrySummaryDto,
  telemetryApi,
} from "../monitoring/telemetryApi";
import "./assetCard.scss";
import { AssetDto, AssetSiteDto, AssetTypeDto } from "./assetsApi";

interface AssetCardProps {
  asset: AssetDto;
  assetType?: AssetTypeDto;
  site?: AssetSiteDto;
  refreshFlag: boolean;
}

export const AssetCard = (props: AssetCardProps) => {
  const { asset, assetType, site, refreshFlag } = props;
  const navigate = useNavigate();
  const [telemetrySummary, setTelemetrySummary] =
    useState<AssetTelemetrySummaryDto | null>();

  useEffect(() => {
    getTelemetrySummary();
  }, [refreshFlag]);

  const getTelemetrySummary = async () => {
    try {
      if (!asset.telemetryId) {
        setTelemetrySummary(null);
        return;
      }

      const summary = await telemetryApi.getAssetTelemetrySummary(
        asset.telemetryId
      );
      setTelemetrySummary(summary);
    } catch (e) {
      setTelemetrySummary(null);
    }
  };

  const renderStatusTags = () => {
    if (telemetrySummary && telemetrySummary.tags) {
      return (
        <div className="status-container-scrollable">
          {telemetrySummary.tags.map((tag, index) => (
            <div key={index} className="status">
              <span className="status-type">{tag.label}</span>
              <span
                className="status-condition"
                style={{
                  backgroundColor: tag.backgroundColor,
                  color: "black",
                }}
              >
                {tag.formattedValue || tag.tagValue}{" "}
                {String(tag.tagValue) === "true" ||
                String(tag.tagValue) === "false"
                  ? ""
                  : getTagUnit(tag.tagId)}
              </span>
            </div>
          ))}
        </div>
      );
    } else {
      return <div className="status-not-available">Not available</div>;
    }
  };

  return (
    <Stack className="asset-card-container">
      <Stack orientation="horizontal">
        <div className="asset-icon">
          <img src={asset.iconUrl} alt="Equipment Icon" />
        </div>
        <Stack className="asset-details">
          <div className="asset-name">{asset.name}</div>
          <div className="asset-type">{assetType?.name}</div>
          <Tag size="sm">
            <div className="cds--type-label-01">Last update at:</div>
            <div className="cds--type-label-01">
              {telemetrySummary?.lastUpdatedAt
                ? formatToLocaleDateTime(telemetrySummary?.lastUpdatedAt)
                : "-"}
            </div>
          </Tag>
        </Stack>
      </Stack>

      <div className="status-container">
        {telemetrySummary ? renderStatusTags() : <InlineLoading />}
      </div>

      <Stack orientation="horizontal" className="card-footer">
        <Tag type={"blue"}>{site?.displayName}</Tag>
        <Button
          kind="ghost"
          onClick={() =>
            navigate(`/monitoring/${asset.id}/asset-details`, {
              state: {
                telemetryId: asset.telemetryId,
                equipmentId: asset.id,
                equipmentName: asset.name,
                iconUrl: asset.iconUrl,
                equipmentType: assetType?.name,
              },
            })
          }
        >
          More details
        </Button>
      </Stack>
    </Stack>
  );
};
