import { TelemetryKey, TelemetryPoint } from "../telemetryConfig";

export type DashboardDisplaySettingsDto =
  | EmbeddedThingsboardDashboardDisplaySettingsDto
  | DiagramDashboardDisplaySettingsDto
  | MetricsDashboardDisplaySettingsDto
  | ExternalDashboardDisplaySettingsDto;

export interface EmbeddedThingsboardDashboardDisplaySettingsDto {
  dashboardId: string;
  externalUrl: string;
  viewerCredentials: string;
}

export interface DiagramSettings {
  width: number;
  height: number;
  fileUrl: string;
  tagRadius: number;
}

export interface DiagramDashboardDisplaySettingsDto {
  diagramSettings: DiagramSettings;
  telemetryPoints: Record<TelemetryKey, TelemetryPoint>;
  telemetryPointLabels?: Record<TelemetryKey, string[]>;
}

export interface ExternalDashboardDisplaySettingsDto {
  externalDashboardLink: string;
}

export enum DashboardGridType {
  Metrics = "metrics",
  AggregatedMetrics = "metrics_aggregate",
  StateGroup = "state",
  TimeBasedStates = "time_states",
  HorizontalGroup = "horizontal",
  VerticalStack = "vertical",
  MetricsSubGrid = "metric_subgrid",
}

export interface DashboardItem {
  tag: string;
  tags?: string[];
  unit: string;
  title: string;
  aggregation?: boolean;
}

export interface MetricStatus {
  tagId: string;
  description: string;
}

export interface DashboardGridColumn {
  type: DashboardGridType;
  title?: string;
  items: DashboardItem[] | DashboardGridColumn[];
  metricStatus?: MetricStatus[];
  stateType?: ItemStateType;
  itemCols?: number;
  colSpan?: number;
  rowSpan?: number;
}

export interface MetricsDashboardDisplaySettingsDto {
  columns: DashboardGridColumn[];
  metricsDashboardSettings: {
    rows: number;
    cols: number;
  };
  reportConfig: {
    tag: string;
    name: string;
    selected: boolean;
  }[];
}

export enum ItemStateType {
  Boolean = "boolean",
  Enum = "enum",
}
