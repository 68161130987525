import {
  Column,
  Grid,
  ProgressIndicator,
  ProgressStep,
  Stack,
} from "@carbon/react";
import { useState } from "react";
import { useNavigate } from "react-router";
import PageTitle from "../../common/PageTitle";
import { AddUserConfirmationList } from "./AddUserConfirmationList";
import { AddUserForm } from "./AddUserForm";
import UserCreationSuccess from "./UserCreationSuccess";
import "./addUserPage.scss";
import { usersApi } from "./usersApi";

const FORM_PROGRESS_INDEX = 0;
const CONFIRM_PROGRESS_INDEX = 1;
const CREATION_SUCCESS_INDEX = 2;

const INITIAL_FORM_DATA = {
  firstName: "",
  lastName: "",
  position: "",
  phoneNumber: "",
  email: "",
  roleId: "",
};

export const AddUserPage = () => {
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [currentStepIndex, setCurrentStepIndex] = useState(FORM_PROGRESS_INDEX);
  const [formErrors, setFormErrors] = useState([]);
  const navigate = useNavigate();

  const resetState = () => {
    setFormData(INITIAL_FORM_DATA);
    setCurrentStepIndex(FORM_PROGRESS_INDEX);
  };

  const handleAddUserProgress = (event: any, formData: any) => {
    event.preventDefault();
    const form = event.target.form;
    if (form && !form.checkValidity()) {
      form.reportValidity();
      return;
    }
    setFormData(formData);
    setCurrentStepIndex(CONFIRM_PROGRESS_INDEX);
  };

  const handleUserFormBack = () => {
    navigate(-1);
  };

  const handleConfirmationBack = () => {
    setCurrentStepIndex(FORM_PROGRESS_INDEX);
  };

  const handleSubmit = async () => {
    try {
      const response = await usersApi.addPartnerUser(formData);

      if (response.userId) {
        setCurrentStepIndex(CREATION_SUCCESS_INDEX);
      }

      return response;
    } catch (e: any) {
      if (typeof e.response.data.message === "string") {
        setFormErrors([e.response.data.message] as any);
      } else if (Array.isArray(e.response.data.message)) {
        setFormErrors(e.response.data.message);
      } else {
        setFormErrors([]);
      }
      return e;
    }
  };

  const renderProgressView = (currentStepIndex: number) => {
    switch (currentStepIndex) {
      case FORM_PROGRESS_INDEX:
        return (
          <AddUserForm
            initialValues={formData}
            handleProgress={handleAddUserProgress}
            handleBack={handleUserFormBack}
          />
        );
      case CONFIRM_PROGRESS_INDEX:
        return (
          <AddUserConfirmationList
            formData={formData}
            handleSubmit={handleSubmit}
            handleBack={handleConfirmationBack}
            formErrors={formErrors}
          />
        );
      case CREATION_SUCCESS_INDEX:
        return <UserCreationSuccess resetState={resetState} />;
      default:
        return <></>;
    }
  };

  return (
    <Stack>
      <PageTitle pageTitle="Add user page" />
      <Stack as="div" className="add-user-page">
        <Grid className="add-user-page-grid">
          <Column md={2} lg={4} as="div" className="user-progress-indicator">
            <ProgressIndicator
              vertical
              currentIndex={currentStepIndex}
              spaceEqually
            >
              <ProgressStep
                label="Step 1"
                secondaryLabel="Add new user"
                description="Provide essential user information"
              />
              <ProgressStep
                label="Step 2"
                secondaryLabel="Confirm Information"
                description="Review your details"
              />
            </ProgressIndicator>
          </Column>
          <Column md={6} lg={12} as="div">
            {renderProgressView(currentStepIndex)}
          </Column>
        </Grid>
      </Stack>
    </Stack>
  );
};
