import { apiClient } from "../../components/api/apiClient";
import { IDto } from "../../components/api/dtos";

export interface UserDto extends IDto {
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  image?: string;
  authId: string;
  createdAt: string;
  id: string;
  isActive: boolean;
  username: string;
  partner: any;
  role: any;
}

export interface CreateUserDto {
  username: string;
  firstName: string;
  lastName: string;
  roleId?: string;
  password: string;
}

export interface UpdateUserDto extends CreateUserDto {}

export interface RoleDto {
  id: string;
  displayName: string;
  description: string | null;
}

export interface CreatePartnerUserDto {
  firstName: string;
  lastName: string;
  position: string;
  phoneNumber: string;
  roleId: string;
}

export interface UpdatePartnerUserDto extends CreatePartnerUserDto {}

export interface PartnerUserDto {
  id: string;
  displayName: string;
  iconUrl: string;
  role: RoleDto;
  email: string;
  phoneNumber: string;
  position: string;
  firstName: string;
  lastName: string;
}

export interface PartnerUserByIdDto {
  user: PartnerUserDto;
}

export interface PartnerUsersDto {
  users: PartnerUserDto[];
}

export interface AddPartnerUserSuccessfulDto {
  userId: string;
}

export interface CheckPartnerUserEmailDto {
  available: boolean;
}

export const usersApi = {
  async getProfile(): Promise<UserDto> {
    const response = await apiClient.get("/users/profile");
    return response.data;
  },

  async getUserProfile(userId: string): Promise<UserDto> {
    const response = await apiClient.get(`/users/${userId}`);
    return response.data;
  },

  async getUsers(partnerId?: string): Promise<UserDto[]> {
    const response = await apiClient.get("/users", {
      params: {
        partnerId,
      },
    });
    return response.data;
  },

  async getPartnerRoles(): Promise<RoleDto[]> {
    const response = await apiClient.get("/partner-user/roles");
    return response.data.roles;
  },

  async getPartnerUsers(): Promise<PartnerUsersDto> {
    const response = await apiClient.get("/partner-user");
    return response.data;
  },

  async getPartnerUserById(id: string): Promise<PartnerUserByIdDto> {
    const response = await apiClient.get(`/partner-user/${id}`);
    return response.data;
  },

  async addPartnerUser(
    user: CreatePartnerUserDto
  ): Promise<AddPartnerUserSuccessfulDto> {
    const response = await apiClient.post("/partner-user", user);
    return response.data;
  },

  async checkPartnerUserEmail(
    email: string
  ): Promise<CheckPartnerUserEmailDto> {
    const response = await apiClient.get(`/partner-user/check?email=${email}`);
    return response.data;
  },

  async updatePartnerUser(id: string, user: UpdatePartnerUserDto) {
    const response = await apiClient.put(`/partner-user/${id}`, user);
    return response.data;
  },

  async deletePartnerUser(userId: string): Promise<any> {
    const response = await apiClient.delete(`/partner-user/${userId}`);
    return response.data;
  },
};
