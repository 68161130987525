import { UserAvatar } from "@carbon/icons-react";
import {
  Button,
  Header,
  HeaderGlobalBar,
  HeaderName,
  HeaderPanel,
  Link,
  Popover,
  SkipToContent,
  SwitcherDivider,
} from "@carbon/react";
import { HttpStatusCode } from "axios";
import { useEffect, useRef, useState } from "react";
import { AppConfig } from "../appConfig";
import { authApi } from "../routes/auth/authApi";
import { useUserStore } from "../routes/users/userStore";
import "./NavBarHeader.scss";

export function NavbarHeader() {
  const [switcherOpened, setSwitcherOpened] = useState<boolean>(false);
  const { user } = useUserStore();
  const buttonRef = useRef(null);
  const headerPanelRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        buttonRef.current &&
        !(buttonRef.current as any).contains(event.target) &&
        headerPanelRef.current &&
        !(headerPanelRef.current as any).contains(event.target)
      ) {
        setSwitcherOpened(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    try {
      const response = await authApi.logout(
        authApi.getUserSession().refreshToken
      );
      const { status } = response!;
      if ([HttpStatusCode.Created, HttpStatusCode.Ok].includes(status)) {
        close();
        window.location.href = AppConfig.authLogoutUrl;
      }
    } catch (error) {
      close();
      window.location.href = AppConfig.authLogoutUrl;
      return error;
    }
  };

  const onClickSideNavExpand = () => {
    setSwitcherOpened(!switcherOpened);
  };

  return (
    <Header aria-label="Protium">
      <SkipToContent />
      <HeaderName href="#" prefix="">
        Protium
      </HeaderName>
      <HeaderGlobalBar>
        <Button
          href="#"
          kind="ghost"
          onClick={onClickSideNavExpand}
          ref={buttonRef}
        >
          <div style={{ color: "black" }}>{user ? `${user.name}` : ""}</div>
        </Button>
        <Button
          kind="ghost"
          aria-label={
            switcherOpened ? "Close profile menu" : "Open profile menu"
          }
          aria-expanded={switcherOpened}
          onClick={onClickSideNavExpand}
          ref={buttonRef}
        >
          <UserAvatar size={20} />
        </Button>
      </HeaderGlobalBar>

      <HeaderPanel
        expanded={switcherOpened}
        href="#switcher-button"
        className="header-switcher"
        addFocusListeners={false}
      >
        <Popover open={true} className="header-popover" ref={headerPanelRef}>
          {user && user.partner && (
            <>
              <div className="header-popover-link">
                <Link className="header-popover-link">
                  <strong>
                    {user?.partner?.name} - {user?.role?.displayName}
                  </strong>
                </Link>
              </div>
              <SwitcherDivider />
            </>
          )}

          <div className="header-popover-link">
            <Link className="header-popover-link">Settings</Link>
          </div>

          <SwitcherDivider />

          <div className="header-popover-link">
            <Link className="header-popover-link" onClick={handleLogout}>
              Logout
            </Link>
          </div>
        </Popover>
      </HeaderPanel>
    </Header>
  );
}
