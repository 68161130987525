import { Button } from "@carbon/react";
import { useEffect, useRef, useState } from "react";
import { Marker, Popup, useMap } from "react-leaflet";
import { useNavigate } from "react-router";
import {
  AssetGroupDto,
  AssetGroupSiteDto,
  AssetGroupTypeDto,
} from "../asset-groups/assetGroupsApi";
import "./assetGroupMarker.scss";

interface AssetGroupMarkerProps {
  site: AssetGroupSiteDto;
  items: AssetGroupDto[];
  itemTypes?: AssetGroupTypeDto[];
}

export const AssetGroupMarker = ({
  site,
  items,
  itemTypes,
}: AssetGroupMarkerProps) => {
  const navigate = useNavigate();
  const [refReady, setRefReady] = useState<boolean>(false);
  const popupRef = useRef<any>(null);
  const map = useMap();
  const [showStatus, setShowStatus] = useState<boolean>(false);

  useEffect(() => {
    if (refReady) {
      map.openPopup(popupRef.current);
    }
  }, [refReady, map]);

  return (
    <div onClick={() => setShowStatus(!showStatus)}>
      <Marker
        position={[site.geoLocation.longitude, site.geoLocation.latitude]}
      >
        <Popup
          ref={(r: any) => {
            popupRef.current = r;
            setRefReady(true);
          }}
          className="custom-popup"
          closeButton={false}
          autoClose={false}
          closeOnClick={false}
          closeOnEscapeKey={false}
        >
          <div className="cds--type-heading-02 popup-site-name">
            {site.displayName}
            <div className="cds--type-regular">{site.fullAddress}</div>
          </div>

          <div className="popup-asset-list">
            {items.length === 0 ? (
              <div>No asset groups available.</div>
            ) : (
              items.map((item, index) => (
                <div key={item.id} className="marker-asset-details">
                  <div className="asset-details">
                    <span className="cds--type-heading-01">{item.name} - </span>
                    <span>{itemTypes ? itemTypes[index]?.name : ""}</span>
                  </div>

                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      kind="primary"
                      size="sm"
                      onClick={() =>
                        navigate(`/monitoring/asset-group/${item.id}/details`, {
                          state: { assetGroupId: item.id },
                        })
                      }
                    >
                      More Details
                    </Button>
                  </div>
                </div>
              ))
            )}
          </div>
        </Popup>
      </Marker>
    </div>
  );
};
