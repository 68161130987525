import { Loading } from "@carbon/react";
import { AssetDto } from "../../assets/assetsApi";
import {
  AssetGroupTelemetryDto,
  TelemetryDashboard,
} from "../assetGroupTelemetryApi";
import { AssetGroupTelemetryDataDto } from "../dtos/asset-group-pfd.dto";
import { AssetGroupPfdDiagram } from "./AssetGroupPfdDiagram";

interface AssetGroupPfdDashboardProps {
  assetGroupTelemetry: AssetGroupTelemetryDto;
  assetGroupTelemetryData: AssetGroupTelemetryDataDto[];
  assets: AssetDto[];
  dashboards: TelemetryDashboard[];
  isDarkMode: boolean;
}

export const POLLING_INTERVAL_MS = 10000;

export const AssetGroupPfdDashboard = ({
  assetGroupTelemetryData,
  dashboards,
  isDarkMode = false,
}: AssetGroupPfdDashboardProps) => {
  const renderContent = () => {
    if (!dashboards || assetGroupTelemetryData.length === 0) {
      return <LoadingContainer />;
    }

    return (
      <div
        style={{
          height: "100vh",
          width: "100%",
          background: isDarkMode ? "black" : "white",
        }}
      >
        <AssetGroupPfdDiagram
          displaySettings={dashboards[0].displaySettings}
          telemetryTagsData={assetGroupTelemetryData}
          isDarkMode={isDarkMode}
        />
      </div>
    );
  };

  const LoadingContainer = () => (
    <div
      style={{
        width: "100%",
        height: "300px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loading withOverlay={false} />
    </div>
  );

  return (
    <div
      style={{
        width: "100%",
        maxHeight: "100%",
      }}
    >
      {renderContent()}
    </div>
  );
};
