import { apiClient } from "../../components/api/apiClient";

interface LoginDto {
  username: string;
  password: string;
}

interface UserSession {
  idToken: string;
  accessToken: string;
  refreshToken: string;
  expiresIn: Date;
}

interface RefreshTokenDto {
  idToken: string;
  refreshToken: string;
}

export const authApi = {
  async login(loginDto: LoginDto) {
    try {
      const response = await apiClient.post("/auth/login", loginDto);

      const userSession: UserSession = {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        expiresIn: response.data.expiresIn,
        idToken: response.data.idToken,
      };

      sessionStorage.setItem("userSession", JSON.stringify(userSession));

      return response;
    } catch (e) {
      return e;
    }
  },

  async getTokenFromCode(code: string) {
    try {
      const response = await apiClient.post("/auth/exchange", {
        code,
      });

      const expiresInInSeconds = response.data.expires_in;
      const currentDateTime = new Date();
      const expirationDateTime = new Date(
        currentDateTime.getTime() + expiresInInSeconds * 1000
      );

      const authSession: UserSession = {
        idToken: response.data.id_token,
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
        expiresIn: expirationDateTime,
      };

      sessionStorage.setItem("userSession", JSON.stringify(authSession));

      return response;
    } catch (e) {
      return e;
    }
  },

  async logout(refreshToken: string) {
    try {
      const response = await apiClient.post("/auth/logout", { refreshToken });
      sessionStorage.clear();
      return response;
    } catch (e) {
      sessionStorage.clear();
    }
  },

  async refreshAccessToken(refreshTokenDto: RefreshTokenDto) {
    try {
      const response = await apiClient.post("/auth/refresh", refreshTokenDto);

      const userSession = JSON.parse(
        sessionStorage.getItem("userSession") || "{}"
      );
      const expiresInInSeconds = response.data.ExpiresIn;
      const currentDateTime = new Date();
      const expirationDateTime = new Date(
        currentDateTime.getTime() + expiresInInSeconds * 1000
      );
      userSession.accessToken = response.data.AccessToken;
      userSession.idToken = response.data.IdToken;
      userSession.refreshToken = refreshTokenDto.refreshToken;
      userSession.expiresIn = expirationDateTime;
      sessionStorage.setItem("userSession", JSON.stringify(userSession));

      return response;
    } catch (e) {
      sessionStorage.clear();
      return undefined;
    }
  },

  getUserSession(): UserSession {
    const userSession = sessionStorage.getItem("userSession");
    return userSession ? JSON.parse(userSession) : null;
  },

  isAuthenticated() {
    const userSession = authApi.getUserSession();

    if (userSession && userSession.accessToken) {
      return true;
    }

    return false;
  },
};
