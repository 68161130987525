import { Stack } from "@carbon/react";
import { useEffect, useState } from "react";
import { formatToLocaleDateTime } from "../../../common/dates";
import { formatTagValue, getTagUnit } from "../../../common/telemetryUtils";
import { DiagramDashboardDisplaySettingsDto } from "../../monitoring/dtos/dashboard-display-settings.dto";
import {
  TelemetryDashboard,
  TelemetryTagsDataResponse,
} from "../../monitoring/telemetryApi";

export interface AssetPfdDiagramPageSidebarProps {
  dashboard: TelemetryDashboard;
  diagramTelemetryTagsData: TelemetryTagsDataResponse;
}

export const AssetPfdDiagramPageSidebar = ({
  dashboard,
  diagramTelemetryTagsData,
}: AssetPfdDiagramPageSidebarProps) => {
  const [diagramTelemetryTags, setDiagramTelemetryTags] =
    useState<TelemetryTagsDataResponse>();

  useEffect(() => {
    setDiagramTelemetryTags(diagramTelemetryTagsData);
  }, [diagramTelemetryTagsData]);

  if (!diagramTelemetryTags) {
    return <Stack>No telemetry data found.</Stack>;
  }

  const findRelatedTags = (key: string) => {
    return diagramTelemetryTags?.tags.filter((tag) =>
      tag.tagId.startsWith(key)
    );
  };

  return (
    <div style={{ overflowY: "auto", height: "calc(100%)" }}>
      <div style={{ border: "1px solid lightGrey" }}>
        {Object.entries(
          (dashboard.displaySettings as DiagramDashboardDisplaySettingsDto)
            .telemetryPoints
        ).map(([tagId]) => {
          const relatedTags = findRelatedTags(tagId);
          return (
            <div
              key={tagId}
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "4px 16px",
                borderBottom: "0.5px solid lightGrey",
              }}
            >
              <Stack>
                {relatedTags.length > 1 ? (
                  relatedTags.map((tag) => (
                    <span key={tag.tagId}>{tag.tagId}</span>
                  ))
                ) : (
                  <span>{tagId}</span>
                )}
              </Stack>

              <Stack>
                {relatedTags.length > 0 ? (
                  relatedTags.map((tag) => (
                    <span key={tag.tagId}>
                      {formatTagValue(
                        tag.tagValue,
                        tag.tagId.toLowerCase().includes("flow") ? 2 : 0
                      )}{" "}
                      {getTagUnit(tag.tagId)}
                    </span>
                  ))
                ) : (
                  <span>-</span>
                )}
              </Stack>
            </div>
          );
        })}
      </div>

      <div style={{ margin: "4px 16px" }}>
        <strong>Last updated:</strong>
        {diagramTelemetryTags?.lastUpdatedAt
          ? formatToLocaleDateTime(diagramTelemetryTags?.lastUpdatedAt)
          : "-"}
      </div>
    </div>
  );
};
