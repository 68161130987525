import { useEffect, useState } from "react";
import { Group, Rect, Text } from "react-konva";
import { formatTagValue, getTagUnit } from "../../../common/telemetryUtils";

export interface AssetDiagramRectTagProps {
  radius: number;
  backgroundColor: string;
  tagValue: string;
  x: number;
  y: number;
  tagKey: string;
}

export const AssetDiagramRectTag = ({
  radius,
  backgroundColor,
  tagValue,
  x,
  y,
  tagKey,
}: AssetDiagramRectTagProps) => {
  const [tempBackgroundColor, setTempBackgroundColor] = useState<string>("");
  const [showToolTip, setShowToolTip] = useState(false);

  useEffect(() => {
    setTempBackgroundColor("green");
    const timeoutId = setTimeout(() => {
      setTempBackgroundColor(backgroundColor);
    }, 150);
    return () => clearTimeout(timeoutId);
  }, [tagValue, backgroundColor]);

  const displayValue = () => {
    const numericValue = parseFloat(tagValue);

    if (!isNaN(numericValue)) {
      return Number.isInteger(numericValue)
        ? tagValue
        : numericValue.toFixed(2);
    } else {
      return "N/A";
    }
  };

  return (
    <Group
      key={tagKey}
      x={x}
      y={y}
      onMouseOver={() => setShowToolTip(true)}
      onMouseLeave={() => setShowToolTip(false)}
    >
      <Rect width={radius * 8} height={radius * 3} fill={tempBackgroundColor} />

      <Text
        text={`${displayValue()} ${getTagUnit(tagKey)}`}
        width={radius * 8}
        height={radius * 3}
        align="center"
        verticalAlign="middle"
        fill={"white"}
        fontSize={radius * 1}
      />

      <Group visible={showToolTip}>
        <Rect
          fill="white"
          width={radius * 8}
          height={radius * 3}
          stroke="black"
          strokeWidth={1}
        />
        <Text
          text={`${tagKey}`}
          width={radius * 4}
          height={radius * 3}
          fontSize={radius * 0.9}
          align="center"
          verticalAlign="middle"
        />
        <Text
          text={`${formatTagValue(
            tagValue,
            tagKey.includes("flow") ? 2 : 0
          )} ${getTagUnit(tagKey)}`}
          width={radius * 4}
          x={(radius * 8) / 2}
          height={radius * 3}
          fontSize={radius * 0.9}
          align="center"
          verticalAlign="middle"
        />
      </Group>
    </Group>
  );
};
