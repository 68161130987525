import { useEffect } from "react";
import { AssetCard } from "./AssetCard";
import "./assetListCardView.scss";
import {
  AssetDto,
  AssetListDto,
  AssetSiteDto,
  AssetTypeDto,
} from "./assetsApi";

export interface AssetListCardViewProps {
  assetList: AssetListDto;
  refreshFlag: boolean;
}

export const AssetListCardView = ({
  assetList,
  refreshFlag,
}: AssetListCardViewProps) => {
  const findSiteById = (
    sites: AssetSiteDto[],
    siteId: string
  ): AssetSiteDto | undefined => {
    return sites.find((site) => site.id === siteId);
  };

  const findAssetTypeById = (
    assetTypes: AssetTypeDto[],
    typeId: string
  ): AssetTypeDto | undefined => {
    return assetTypes.find((type) => type.id === typeId);
  };

  useEffect(() => {}, [refreshFlag]);

  const renderEquipmentCards = (
    assetList: AssetListDto,
    refreshFlag: boolean
  ) => {
    const { assets, sites, assetTypes } = assetList;

    return assets.map((asset: AssetDto) => {
      const site = findSiteById(sites, asset.siteId);
      const assetType = findAssetTypeById(assetTypes, asset.assetTypeId);

      return (
        <div key={asset.id} className="card-column">
          <AssetCard
            asset={asset}
            site={site}
            assetType={assetType}
            refreshFlag={refreshFlag}
          />
        </div>
      );
    });
  };

  return (
    <div className="card-view">
      {renderEquipmentCards(assetList, refreshFlag)}
    </div>
  );
};
