import {
  Button,
  ButtonSet,
  Column,
  FileUploader,
  Form,
  FormLabel,
  Grid,
  Stack,
  TextInput,
} from "@carbon/react";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import "../../common/scss/form.scss";

interface NewCustomerFormProps {
  initialValues: any;
  handleProgress: any;
}

export const NewCustomerForm = ({
  initialValues,
  handleProgress,
}: NewCustomerFormProps) => {
  const [formData, setFormData] = useState(initialValues);
  const [validationState, setValidationState] = useState({
    name: true,
    description: true,
    countryCode: true,
    city: true,
    streetAddress: true,
    postalCode: true,
    phoneNumber: true,
  });
  const navigate = useNavigate();

  const handleChange = (event: any) => {
    const { id, value } = event.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleBlur = (
    event:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLSelectElement>
  ) => {
    const { id, value } = event.target;
    switch (id) {
      case "name":
        setInputFieldValid("name", value);
        break;
      case "description":
        setInputFieldValid("description", value);
        break;
      case "countryCode":
        setInputFieldValid("countryCode", value);
        break;
      case "phoneNumber":
        setPhoneValid(event);
        break;
      case "city":
        setInputFieldValid("city", value);
        break;
      case "postalCode":
        setInputFieldValid("postalCode", value);
        break;
      case "streetAddress":
        setInputFieldValid("streetAddress", value);
        break;
      default:
        break;
    }
  };

  const setInputFieldValid = (inputId: any, value: any) => {
    setValidationState((prevState) => ({ ...prevState, [inputId]: !!value }));
  };

  const setPhoneValid = (event: any) => {
    setValidationState((prevState) => ({
      ...prevState,
      phoneNumber: /^\+61\d{9}$/.test(event.target.value),
    }));
  };

  return (
    <Form className="new-form" onSubmit={(e) => handleProgress(e, formData)}>
      <Stack>
        <Stack orientation="horizontal">
          <Stack>
            <div
              className="text-sans"
              dir="auto"
              style={{
                fontWeight: 400,
                padding: 0,
                margin: 0,
              }}
            >
              Add Customer <br></br>
              <FormLabel>Provide essential customer information</FormLabel>
            </div>
          </Stack>
        </Stack>

        <Grid>
          <Column md={8} lg={16}>
            <TextInput
              type="text"
              id={"name"}
              labelText={"Company name"}
              value={formData.name}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-input"
              invalid={!validationState.name}
              invalidText={"This field is required"}
              required
            />
          </Column>

          <Column md={3} lg={6}>
            <TextInput
              type="text"
              id={"description"}
              labelText={"Company description"}
              value={formData.description}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-input"
              required
              invalid={!validationState.description}
              invalidText={"This field is required"}
            />
          </Column>

          <Column md={3} lg={6}>
            <TextInput
              type="text"
              id={"countryCode"}
              labelText={"Country"}
              value={formData.countryCode}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-input"
              required
              invalid={!validationState.countryCode}
              invalidText={"This field is required"}
            />
          </Column>

          <Column md={3} lg={6}>
            <TextInput
              type="text"
              id={"city"}
              labelText={"City"}
              value={formData.city}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-input"
              required
              invalid={!validationState.city}
              invalidText={"This field is required"}
            />
          </Column>

          <Column md={3} lg={6}>
            <TextInput
              type="number"
              id={"postalCode"}
              labelText={"Zip / Postal Code"}
              value={formData.postalCode}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-input"
              required
              invalid={!validationState.postalCode}
              invalidText={"This field is required"}
            />
          </Column>

          <Column md={3} lg={6}>
            <TextInput
              type="text"
              id={"streetAddress"}
              required
              labelText={"Street Address"}
              value={formData.streetAddress}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-input"
              invalid={!validationState.streetAddress}
              invalidText={"This field is required"}
            />
          </Column>

          <Column md={3} lg={6}>
            <TextInput
              type="text"
              id={"phoneNumber"}
              required
              labelText={"Phone Number ( +61 )"}
              value={formData.phoneNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-input"
              pattern="^\+61\d{9}$"
              invalid={!validationState.phoneNumber}
              invalidText={
                !validationState.phoneNumber
                  ? "Phone number must be in the format +61xxxxxxxxx."
                  : "This field is required"
              }
            />
          </Column>

          <Column md={3} lg={6}>
            <FileUploader
              labelTitle="Company Logo"
              labelDescription="Only .png / .jpeg format are accepted"
              buttonLabel="Upload"
              buttonKind="tertiary"
              size="md"
              filenameStatus="edit"
              accept={[".jpg", ".png"]}
              multiple={false}
              disabled={false}
              iconDescription="Add file"
              name=""
            />
          </Column>
        </Grid>

        <ButtonSet className="form-button-group">
          <Button type="submit">Next</Button>
          <Button
            kind="danger--tertiary"
            onClick={() => navigate("/customers")}
          >
            Cancel
          </Button>
        </ButtonSet>
      </Stack>
    </Form>
  );
};
