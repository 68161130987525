import { apiClient } from "../../components/api/apiClient";
import { AssetDto } from "../assets/assetsApi";
import { TelemetryDashboardType } from "../monitoring/enums/TelemetryDashboardType.enum";
import { AssetGroupDto } from "./assetGroupsApi";
import { AssetGroupTelemetryDataResponse } from "./dtos/asset-group-pfd.dto";

export const ASSET_GROUP_TELEMETRY_POLLING_RATE_MS = 5000;

export interface TelemetryDashboard {
  id: string;
  displayName: string;
  type: TelemetryDashboardType;
  displaySettings?: any;
}

export interface AssetGroupTelemetryResponseDto {
  assetGroup: AssetGroupDto;
  assets: AssetDto[];
  assetGroupTelemetry: AssetGroupTelemetryDto;
  dashboards: TelemetryDashboard[];
}

export interface AssetGroupTelemetryDto {
  id: string;
}

export const assetGroupTelemetryApi = {
  async getAssetGroupTelemetryValues(
    assetGroupTelemetryId: string
  ): Promise<AssetGroupTelemetryDataResponse> {
    const response = await apiClient.get(
      `/asset-group-telemetry/${assetGroupTelemetryId}/group-tag-values`
    );
    return response.data;
  },

  async getAssetGroupTelemetry(
    assetGroupId: string
  ): Promise<AssetGroupTelemetryResponseDto> {
    const response = await apiClient.get(
      `/asset-group-telemetry/asset-group/${assetGroupId}`
    );
    return response.data;
  },
};
