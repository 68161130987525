import { Button, InlineLoading, Link, Stack, Tag } from "@carbon/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { formatToLocaleDateTime } from "../../common/dates";
import "./assetGroupCard.scss";
import {
  AssetGroupDto,
  AssetGroupSiteDto,
  AssetGroupTypeDto,
} from "./assetGroupsApi";
import { assetGroupTelemetryApi } from "./assetGroupTelemetryApi";
import { AssetGroupTelemetryDataResponse } from "./dtos/asset-group-pfd.dto";

interface AssetGroupCardProps {
  assetGroup: AssetGroupDto;
  assetGroupType?: AssetGroupTypeDto;
  site?: AssetGroupSiteDto;
  refreshFlag: boolean;
}

export const AssetGroupCard = (props: AssetGroupCardProps) => {
  const { assetGroup, assetGroupType, site, refreshFlag } = props;
  const navigate = useNavigate();
  const [assetGroupTelemetryData, setAssetGroupTelemetryData] =
    useState<AssetGroupTelemetryDataResponse | null>(null);

  useEffect(() => {
    getAssetGroupTelemetryValues();
  }, [refreshFlag]);

  const getAssetGroupTelemetryValues = async () => {
    if (!assetGroup.telemetryId) return;

    try {
      const assetGroupTelemetryValuesResponse =
        await assetGroupTelemetryApi.getAssetGroupTelemetryValues(
          assetGroup.telemetryId
        );
      setAssetGroupTelemetryData(assetGroupTelemetryValuesResponse);
    } catch (e) {
      //
    }
  };

  const assetsTelemetryLastUpdatedAt = assetGroup.assets.map((asset) => {
    const telemetryData = assetGroupTelemetryData?.assetGroupTelemetryData.find(
      (data) => data.telemetryId === asset.telemetryId
    );

    return {
      assetName: asset.name,
      lastUpdateAt: telemetryData
        ? formatToLocaleDateTime(telemetryData.lastUpdateAt)
        : "-",
    };
  });

  const renderAssetList = () => {
    if (assetGroup && assetGroup.assets.length > 0) {
      return (
        <div className="status-container-scrollable">
          {assetsTelemetryLastUpdatedAt.map((item, index) => (
            <div key={index} className="status">
              <span className="status-type">
                <Link
                  onClick={() =>
                    navigate(
                      `/monitoring/${assetGroup.assets[index].id}/asset-details`
                    )
                  }
                >
                  {item.assetName}
                </Link>
              </span>
              <div
                className="status-condition cds--type-helper-text-01"
                style={{ padding: "0px 2px", minWidth: "50%" }}
              >
                <span>Last updated at: </span>
                {item.lastUpdateAt === "-" ? (
                  <span>
                    <InlineLoading />
                  </span>
                ) : (
                  <div style={{ color: "black", padding: "0 4px" }}>
                    {item.lastUpdateAt}{" "}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return <div className="status-not-available">No grouped assets</div>;
    }
  };

  return (
    <Stack className="asset-card-container">
      <Stack orientation="horizontal">
        <div className="asset-icon">
          <img src={assetGroup.iconUrl} alt="Equipment Icon" />
        </div>
        <Stack className="asset-details">
          <div className="asset-name">{assetGroup.name}</div>
          <div className="asset-type">{assetGroupType?.name}</div>
        </Stack>
      </Stack>

      <div className="status-container">
        {assetGroup ? renderAssetList() : <InlineLoading />}
      </div>

      <Stack orientation="horizontal" className="card-footer">
        <Tag type={"blue"}>{site?.displayName}</Tag>
        <Button
          kind="ghost"
          onClick={() =>
            navigate(`/monitoring/asset-group/${assetGroup.id}/details`)
          }
        >
          More details
        </Button>
      </Stack>
    </Stack>
  );
};
