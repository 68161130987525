import { Button, Loading } from "@carbon/react";
import { Text } from "@carbon/react/lib/components/Text";
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import { AppConfig } from "../../appConfig";
import ErrorMessageComponent from "../../common/ErrorMessageComponent";
import { getRegionFlag } from "../../common/regionUtils";
import { useUserStore } from "../users/userStore";
import { usersApi } from "../users/usersApi";
import "./auth.scss";
import { authApi } from "./authApi";

export const LoginPage = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const updateCurrentUser = useUserStore((state) => state.updateCurrentUser);

  useEffect(() => {
    completeLoginUsingAuthCode();
  }, []);

  const completeLoginUsingAuthCode = async () => {
    await fetchSessionFromAuthCode();
  };

  const loadUserProfile = async () => {
    const response = await usersApi.getProfile();

    if (!axios.isAxiosError(response)) {
      updateCurrentUser(response);
      setIsLoading(false);
      navigate("/dashboard");
    } else {
      const axiosError = response as AxiosError<{ message: string }>;
      setSearchParams({});
      setIsLoading(false);
      setErrorMessage(axiosError.response?.data?.message || "");
    }
  };

  const fetchSessionFromAuthCode = async () => {
    const authCode = searchParams.get("code");

    if (authCode && authCode.length > 6) {
      setIsLoading(true);

      const response = await authApi.getTokenFromCode(authCode);

      if (!axios.isAxiosError(response)) {
        setSearchParams({});
        await loadUserProfile();
      } else {
        const axiosError = response as AxiosError<{ message: string }>;
        setSearchParams({});
        setIsLoading(false);
        setErrorMessage(axiosError.response?.data?.message || "");
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="login-container">
          <Loading withOverlay={false} />
        </div>
      ) : (
        <>
          <div className="login-container">
            <Link to={AppConfig.authRedirectUrl}>
              <Button variant="filled">Click here to login</Button>
            </Link>

            <div
              className="cds--type-body-01"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span>Region - </span> {getRegionFlag()}
            </div>
          </div>

          <div>
            <div className="footer">
              <div className="inner">
                <Text>
                  {"All rights reserved"}
                  <br />
                  {`Version ${AppConfig.appVersion}`} -
                  {` Branch: ${AppConfig.appBranch}`}
                </Text>
              </div>
            </div>
          </div>
        </>
      )}
      <ErrorMessageComponent message={errorMessage} />
    </div>
  );
};
