import { Route, Routes } from "react-router";
import { LoginPage } from "./LoginPage";

export const LoginNavigator = () => {
  return (
    <Routes>
      <Route path="/" index element={<LoginPage />} />
      <Route path=":code" element={<LoginPage />} />
    </Routes>
  );
};
