export enum TelemetryType {
  Celsius = "°C",
  PSI = "psi",
  Kilogram = "kg",
  Second = "s",
  Minute = "min",
  Hour = "h",
  Day = "d",
  Percentage = "%",
  RPM = "rpm",
}

export enum SensorType {
  Temperature = "Temperature",
  Pressure = "Pressure",
}

export const SENSOR_DISCONNECT_VALUES = {
  [SensorType.Temperature]: "5000",
};
