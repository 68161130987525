export interface AssetGroupPfdDiagramDisplaySettings {
  width: number;
  height: number;
  fileUrl: string;
  tagRadius: number;
}

export enum TelemetryPointType {
  Number = "number",
  Enum = "enum",
  Boolean = "boolean",
}

export interface TelemetryPoint {
  x: number;
  y: number;
  type: TelemetryPointType;
  tagId: string;
  name?: string;
  assetName: string;
  enumIndex?: number;
  isRateOfChange?: boolean;
}

export interface AssetGroupPfdDiagramDashboardDisplaySettingsDto {
  diagramSettings: AssetGroupPfdDiagramDisplaySettings;
  telemetryPoints: TelemetryPoint[];
  telemetryIds: Record<string, string>;
  warningStates: string[];
}

export interface AssetGroupTelemetryDataResponse {
  assetGroupTelemetryData: AssetGroupTelemetryDataDto[];
}

export interface AssetGroupTelemetryDataDto {
  telemetryId: string;
  data: TelemetryTagDataDto[];
  lastUpdateAt: Date;
}

export interface TelemetryTagDataDto {
  tagId: string;
  tagValue: string;
  timestamp: string;
}
