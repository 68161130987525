import { Route, Routes } from "react-router";
import { DashboardHomePage } from "./DashboardHomePage";

export const DashboardNavigator = () => {
  return (
    <Routes>
      <Route index element={<DashboardHomePage />} />
    </Routes>
  );
};
