import { Group, Rect, Text } from "react-konva";
import { formatTagValue, getTagUnit } from "../../../common/telemetryUtils";

export interface DiagramTagToolTipProps {
  tagId: string;
  tagValue: string;
  point: { x: number; y: number };
  tagRadius: number;
  defaultTagValueBackgroundColor: string;
  defaultTextColor: string;
}

export const DiagramTagToolTip = ({
  tagId,
  tagValue,
  point,
  tagRadius,
  defaultTagValueBackgroundColor,
  defaultTextColor,
}: DiagramTagToolTipProps) => {
  return (
    <Group key={tagId} x={point.x - (tagRadius * 5) / 2} y={point.y}>
      <Rect
        width={tagRadius * 5}
        height={tagRadius * 2}
        fill={defaultTagValueBackgroundColor}
        cornerRadius={4}
      />
      <Text
        width={tagRadius * 5}
        height={tagRadius * 2}
        fontSize={tagRadius * 0.75}
        text={`${formatTagValue(
          tagValue,
          tagId.toLowerCase().includes("flow") ? 2 : 0
        )} ${getTagUnit(tagId)}`}
        fill={defaultTextColor}
        align="center"
        verticalAlign="middle"
      />
    </Group>
  );
};
