import { apiClient } from "../../components/api/apiClient";
import { AssetGroupDto } from "../asset-groups/assetGroupsApi";
import { TelemetryDashboardType } from "../monitoring/enums/TelemetryDashboardType.enum";
import { TelemetryPointsConfig } from "../monitoring/telemetryConfig";

export interface AssetListDto {
  assets: AssetDto[];
  sites: AssetSiteDto[];
  assetTypes: AssetTypeDto[];
}

export interface AssetDto {
  id: string;
  name: string;
  assetTypeId: string;
  siteId: string;
  telemetryId: string;
  iconUrl: string;
  assetType: AssetTypeDto;
  assetGroups: AssetGroupDto[];
}

export interface AssetTypeDto {
  description: string;
  iconUrl: string;
  mobilityType: string;
  name: string;
  id: string;
}

export interface AssetSiteDto {
  id: string;
  displayName: string;
  fullAddress: string;
  geoLocation: {
    latitude: number;
    longitude: number;
  };
}

export interface AssetTypeDto {
  id: string;
  name: string;
  description: string;
  type: number;
  iconUrl: string;
}

export interface DashboardDto {
  id: string;
  displayName: string;
  type: TelemetryDashboardType;
  displaySettings: TelemetryPointsConfig;
}

export const assetsApi = {
  async getAssetList(): Promise<AssetListDto> {
    const response = await apiClient.get("/asset");
    return response.data;
  },

  async getAssetById(assetId: string): Promise<any> {
    const response = await apiClient.get(`/asset/${assetId}`);
    return response.data;
  },
};
