import { Route, Routes } from "react-router";
import { FleetManagetmentHome } from "./FleetManagementHome";

export const FleetManagementNavigator = () => {
  return (
    <Routes>
      <Route index element={<FleetManagetmentHome />} />
    </Routes>
  );
};
