interface ErrorMessageComponentProps {
  message: string;
}

const ErrorMessageComponent = ({ message }: ErrorMessageComponentProps) => {
  return (
    <div>
      <p color="red">{message}</p>
    </div>
  );
};

export default ErrorMessageComponent;
