import {
  Button,
  ButtonSet,
  Column,
  Form,
  Grid,
  Select,
  SelectItem,
  Stack,
  TextInput,
} from "@carbon/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { CustomBreadcrumb } from "../../common/CustomBreadcrumb";
import "./editUserPage.scss";
import { PartnerUserDto, RoleDto, usersApi } from "./usersApi";

const INITIAL_INVALID_FIELDS = {
  firstName: false,
  lastName: false,
  position: false,
  phoneNumber: false,
  roleId: false,
};

export const EditUserPage = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    position: "",
    phoneNumber: "",
    roleId: "",
  });
  const [availableRoles, setAvailableRoles] = useState<RoleDto[]>();
  const { userId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState<PartnerUserDto | null>(null);
  const [invalidFields, setInvalidFields] = useState(INITIAL_INVALID_FIELDS);

  useEffect(() => {
    getRoles();
    getUser(userId!);
  }, []);

  const getRoles = async () => {
    const roles = await usersApi.getPartnerRoles();
    setAvailableRoles(roles);
  };

  const getUser = async (id: string) => {
    const response = await usersApi.getPartnerUserById(id);
    const user = response.user;
    setUser(user);
    setFormData({
      firstName: user!.firstName,
      lastName: user!.lastName,
      position: user!.position,
      phoneNumber: user!.phoneNumber,
      roleId: user!.role.id,
    });
  };

  const handleChange = (event: any) => {
    const { id, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const getCurrentInvalidFields = () => {
    const phoneNumberRegex: RegExp = /^\+61\s?\d{9}$/;

    return {
      firstName: !formData.firstName,
      lastName: !formData.lastName,
      position: !formData.position,
      phoneNumber:
        !formData.phoneNumber || !phoneNumberRegex.test(formData.phoneNumber),
      roleId: !formData.roleId,
    };
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const invalidFields = getCurrentInvalidFields();
    const hasInvalidFields = Object.values(invalidFields).some(
      (value) => value
    );
    if (hasInvalidFields) {
      setInvalidFields(getCurrentInvalidFields());
      return;
    }

    const response = await usersApi.updatePartnerUser(userId!, formData);

    if (response) {
      navigate("/users");
    }
  };

  const breadcrumbItems = [
    { label: "Users", href: "/users" },
    {
      label: user ? user?.displayName : "Loading...",
      showLoading: !user,
      isCurrentPage: true,
    },
  ];

  return (
    <div>
      <CustomBreadcrumb items={breadcrumbItems} />

      <div className="form-container">
        <Form aria-label="sample form" className="form" onSubmit={handleSubmit}>
          <Stack gap={5}>
            <p className="cds--type-fluid-heading-03">Edit user details</p>
            <Stack orientation="horizontal">
              <TextInput
                type="text"
                required
                id={"firstName"}
                labelText={"First name"}
                value={formData.firstName}
                onChange={handleChange}
              />
              <TextInput
                type="text"
                required
                id={"lastName"}
                labelText={"Last name"}
                value={formData.lastName}
                onChange={handleChange}
              />
            </Stack>

            <Stack orientation="horizontal">
              <TextInput
                type="text"
                required
                id={"position"}
                labelText={"Position"}
                value={formData.position}
                onChange={handleChange}
              />

              <TextInput
                type="text"
                required
                id={"phoneNumber"}
                labelText={"Phone Number"}
                value={formData.phoneNumber}
                onChange={handleChange}
                invalid={invalidFields.phoneNumber}
                invalidText={"Phone number must be an Australian number."}
              />
            </Stack>

            <Grid style={{ margin: "0", padding: "0" }}>
              <Column md={4} lg={8} style={{ margin: "0 8px 0 0" }}>
                <Select
                  id={"roleId"}
                  labelText="Select a Role"
                  value={formData.roleId}
                  onChange={handleChange}
                >
                  {availableRoles &&
                    availableRoles.map((role) => (
                      <SelectItem
                        key={role.id}
                        value={role.id}
                        text={role.displayName}
                      />
                    ))}
                </Select>
              </Column>
            </Grid>

            <ButtonSet className="form-button-set">
              <Button type="submit">Submit</Button>
              <Button kind="tertiary" onClick={() => navigate("/users")}>
                Cancel
              </Button>
            </ButtonSet>
          </Stack>
        </Form>
      </div>
    </div>
  );
};
