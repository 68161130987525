import { useEffect } from "react";
import { AssetGroupCard } from "./AssetGroupCard";
import {
  AssetGroupDto,
  AssetGroupListDto,
  AssetGroupSiteDto,
  AssetGroupTypeDto,
} from "./assetGroupsApi";

export interface AssetGroupListProps {
  assetGroupList: any;
  refreshFlag: boolean;
}

export const AssetGroupList = ({
  assetGroupList,
  refreshFlag,
}: AssetGroupListProps) => {
  useEffect(() => {}, [refreshFlag]);

  const findSiteById = (
    sites: AssetGroupSiteDto[],
    siteId: string
  ): AssetGroupSiteDto | undefined => {
    return sites.find((site) => site.id === siteId);
  };

  const findAssetGroupTypeById = (
    assetGroupTypes: AssetGroupTypeDto[],
    typeId: string
  ): AssetGroupTypeDto | undefined => {
    return assetGroupTypes.find((type) => type.id === typeId);
  };

  const renderAssetGroupCards = (
    assetGroupList: AssetGroupListDto,
    refreshFlag: boolean
  ) => {
    const { assetGroups, sites, assetGroupTypes } = assetGroupList;

    return assetGroups.map((assetGroup: AssetGroupDto) => {
      const site = findSiteById(sites, assetGroup.siteId);
      const assetGroupType = findAssetGroupTypeById(
        assetGroupTypes,
        assetGroup.assetTypeId
      );

      return (
        <div key={assetGroup.id} className="card-column">
          <AssetGroupCard
            assetGroup={assetGroup}
            site={site}
            assetGroupType={assetGroupType}
            refreshFlag={refreshFlag}
          />
        </div>
      );
    });
  };

  return (
    <div className="card-view">
      {renderAssetGroupCards(assetGroupList, refreshFlag)}
    </div>
  );
};
