import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import RequireAuthRoutes from "./components/RequireAuthRoutes";
import ScrollToTop from "./components/ScrollToTop";
import AppLayout from "./components/layouts/AppLayout";
import { LoginNavigator } from "./routes/auth/LoginNavigator";
import { CustomerNavigator } from "./routes/customers/CustomerNavigator";
import { DashboardNavigator } from "./routes/dashboards/DashboardNavigator";
import { FleetManagementNavigator } from "./routes/fleet-management/FleetManagementNavigator";
import { MonitoringNavigator } from "./routes/monitoring/MonitoringNavigator";
import { ReportNavigator } from "./routes/reports/ReportNavigator";
import { UsersNavigator } from "./routes/users/UsersNavigator";

export const AppNavigator = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="login/*" element={<LoginNavigator />} />
        <Route index element={<Navigate to={"login"} />} />
        <Route
          path="*"
          element={
            <RequireAuthRoutes>
              <Routes>
                <Route path="/" element={<AppLayout />}>
                  {/* Home */}
                  <Route path="dashboard/*" element={<DashboardNavigator />} />
                  <Route path="*" element={<Navigate to={"dashboard"} />} />

                  {/* Monitoring */}
                  <Route
                    path="monitoring/*"
                    element={<MonitoringNavigator />}
                  />
                  <Route path="*" element={<Navigate to={"monitoring"} />} />

                  {/* Fleet Management */}
                  <Route
                    path="fleet-management/*"
                    element={<FleetManagementNavigator />}
                  />
                  <Route
                    path="*"
                    element={<Navigate to={"fleet-management"} />}
                  />

                  {/* Report */}
                  <Route path="report/*" element={<ReportNavigator />} />
                  <Route path="*" element={<Navigate to={"report"} />} />

                  {/* Customer */}
                  <Route path="customers/*" element={<CustomerNavigator />} />
                  <Route path="*" element={<Navigate to={"customers"} />} />

                  {/* Users */}
                  <Route path="users/*" element={<UsersNavigator />} />
                  <Route path="*" element={<Navigate to={"users"} />} />
                </Route>
              </Routes>
            </RequireAuthRoutes>
          }
        />
      </Routes>
    </Router>
  );
};
