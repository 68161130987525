import { ScrollArea } from "../../../common/ScrollArea";
import {
  DashboardItem,
  ItemStateType,
} from "../../monitoring/dtos/dashboard-display-settings.dto";
import classes from "./DashboardPage.module.css";

interface DashboardStateCardProps {
  tags: DashboardItem[];
  title: string;
  itemCols?: number;
  getTagByTagId: any;
  stateType: ItemStateType;
}

const DEFAULT_COLS = 3;

export const DashboardStateCard = ({
  tags,
  title,
  itemCols,
  getTagByTagId,
  stateType,
}: DashboardStateCardProps) => {
  const getStateStyle = (
    title: string,
    type: string,
    tag: string,
    index?: number
  ) => {
    const tagValue = getTagByTagId(tag)?.tagValue;

    if (type === ItemStateType.Boolean) {
      return String(tagValue) === "true"
        ? title.toLowerCase().includes("interlocks")
          ? classes.warningStateItem
          : classes.activeStateItem
        : classes.stateTag;
    } else {
      return Number(tagValue) === index
        ? classes.activeStateItem
        : classes.stateTag;
    }
  };

  const columnWidth = `${100 / (itemCols || DEFAULT_COLS)}%`;

  return (
    <div
      style={{
        background: "rgb(25, 24, 24)",
        overflow: "hidden",
        width: "100%",
        height: "100%",
      }}
    >
      <ScrollArea>
        <div style={{ padding: "2px 6px 8px " }}>{title}</div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {tags.map((tag, index) => (
            <div
              key={index}
              className={`${classes.stateTag} ${getStateStyle(
                title,
                stateType,
                tag.tag,
                index
              )}`}
              style={{
                width: columnWidth,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                margin: "2px 4px",
                padding: itemCols ? "5px" : "10px",
              }}
            >
              <span style={{ fontSize: itemCols ? "16px" : "14px" }}>
                {tag.title}
              </span>
            </div>
          ))}
        </div>
      </ScrollArea>
    </div>
  );
};
