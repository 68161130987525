import { TagValueData } from "../../monitoring/telemetryApi";

interface DashboardMetricsAggregateCardProps {
  items: {
    tags: string[];
    tagsUnit: string;
    tag: string;
    unit: string;
    title: string;
  }[];
  getTagByTagId: (tag: string) => TagValueData | undefined;
}

export const DashboardMetricsAggregateCard = ({
  items,
  getTagByTagId,
}: DashboardMetricsAggregateCardProps) => {
  const mainTag = items[0];

  const tagValues = mainTag.tags
    .map((tag) => getTagByTagId(tag)?.tagValue)
    .filter((tagValue) => tagValue !== undefined && !isNaN(Number(tagValue)))
    .map((tagValue) => Number(tagValue));

  const meanValue =
    tagValues.length > 0
      ? (
          tagValues.reduce((sum, value) => sum + value, 0) / tagValues.length
        ).toFixed(0)
      : "-";

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        background: "rgb(25, 24, 24)",
        display: "flex",
        gap: "0",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "calc(100% * 2/8)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            height: "calc(100% - 50px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>{mainTag.title}</span>
          <span style={{ fontSize: "1.25rem" }}>
            {getTagByTagId(mainTag.tag)
              ? getTagByTagId(mainTag.tag)?.tagValue
              : "-"}{" "}
            {mainTag.unit}
          </span>
          <span
            style={{
              fontSize: "0.875rem",
              letterSpacing: "1px",
              color: "grey",
            }}
          >
            {mainTag.tag}
          </span>
        </div>
      </div>

      <div
        style={{
          height: "100%",
          width: "calc(100% * 4/8)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          {mainTag.tags.map((tag, index) => (
            <div
              key={index}
              style={{
                height: "110px",
                width: "110px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                borderRadius: "50%",
                background: "#4c4c4c",
                flexDirection: "column",
              }}
            >
              <span style={{ fontSize: "1.25rem" }}>
                {getTagByTagId(tag) ? getTagByTagId(tag)?.tagValue : "-"}{" "}
                {mainTag.tagsUnit}
              </span>
              <span
                style={{
                  fontSize: "0.875rem",
                  letterSpacing: "1px",
                  color: "grey",
                }}
              >
                {tag}
              </span>
            </div>
          ))}
        </div>
      </div>

      <div
        style={{
          height: "100%",
          width: "calc(100% * 2/8)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            height: "150px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>Delta</span>
          <div style={{ display: "flex", gap: "4px" }}>
            <span style={{ fontSize: "1.875rem" }}>{"-"}</span>
            <span>{mainTag.tagsUnit}</span>
          </div>
        </div>

        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            height: "150px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>Mean</span>
          <div style={{ display: "flex", gap: "4px" }}>
            <span style={{ fontSize: "1.875rem" }}>{meanValue}</span>
            <span>{mainTag.tagsUnit}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
