import { Loading, Tag } from "@carbon/react";
import { formatToLocaleDateTime } from "../../../common/dates";
import {
  DashboardGridType,
  MetricsDashboardDisplaySettingsDto,
} from "../../monitoring/dtos/dashboard-display-settings.dto";
import {
  TelemetryDashboard,
  TelemetryTagsDataResponse,
} from "../../monitoring/telemetryApi";
import { AssetDto } from "../assetsApi";
import { DashboardMetricsAggregateCard } from "./DashboardMetricsAggregateCard";
import { DashboardMetricsCard } from "./DashboardMetricsCard";
import { DashboardStateCard } from "./DashboardStateCard";
import { HorizontalGroupCard } from "./HorizontalGroupCard";
import { VerticalStackCard } from "./VerticalStackCard";

interface DashboardPageProps {
  assetTelemetry: any;
  assetTelemetryData: TelemetryTagsDataResponse;
  asset: AssetDto;
  dashboards: TelemetryDashboard[];
}

export const DashboardPage = ({
  assetTelemetryData,
  asset,
  assetTelemetry,
  dashboards,
}: DashboardPageProps) => {
  const getTagByTagId = (tagId: string) =>
    assetTelemetryData?.tags.find((tag) => tag.tagId === tagId);

  const dashboardDisplaySettings = dashboards[0]
    .displaySettings as MetricsDashboardDisplaySettingsDto;
  const renderGridItem = (item: any, idx: number) => {
    const gridHeight = "calc(100vh - 150px)";
    const span = item.colSpan;
    const height = `calc(${gridHeight} / ${dashboardDisplaySettings.metricsDashboardSettings.rows} * ${item.rowSpan})`;

    return (
      <div
        key={idx}
        style={{
          gridColumn: `span ${span}`,
          height,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {item.type === DashboardGridType.Metrics && (
          <div style={{ height: "100%" }}>
            {item.items.map((metricItem: any, cardIdx: number) => (
              <div
                key={cardIdx}
                style={{
                  height: item.items.length > 1 ? "calc(100% - 4px)" : "100%",
                  marginBottom: "8px",
                }}
              >
                <DashboardMetricsCard
                  title={metricItem.title}
                  unit={metricItem.unit}
                  tagId={metricItem.tag}
                  value={String(getTagByTagId(metricItem.tag)?.tagValue) || "-"}
                  aggregationUnit={
                    metricItem.aggregation ? metricItem.unit : null
                  }
                  description={metricItem.tag}
                  assetTelemetry={assetTelemetry}
                />
              </div>
            ))}
          </div>
        )}
        {item.type === DashboardGridType.StateGroup && (
          <DashboardStateCard
            tags={item.items}
            title={item.title}
            itemCols={item.itemCols}
            getTagByTagId={getTagByTagId}
            stateType={item.stateType}
          />
        )}
        {item.type === DashboardGridType.HorizontalGroup && (
          <HorizontalGroupCard
            metrics={item.items}
            title={item.title}
            getTagByTagId={getTagByTagId}
          />
        )}
        {item.type === DashboardGridType.VerticalStack && (
          <VerticalStackCard items={item.items} getTagByTagId={getTagByTagId} />
        )}
        {item.type === DashboardGridType.AggregatedMetrics && (
          <DashboardMetricsAggregateCard
            items={item.items}
            getTagByTagId={getTagByTagId}
          />
        )}
      </div>
    );
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "black",
        color: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "12px",
        }}
      >
        <>{asset.name}</>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            padding: "8px 0",
            marginRight: "100px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "8px",
            }}
          >
            <span style={{ fontSize: "12px", color: "grey" }}>
              Last Updated at:
            </span>
            <span style={{ fontSize: "12px" }} className={"cds--type-label-02"}>
              {assetTelemetryData.lastUpdatedAt
                ? formatToLocaleDateTime(assetTelemetryData.lastUpdatedAt)
                : "-"}
            </span>
          </div>

          <Tag
            style={{
              background: assetTelemetryData.onlineStatus ? "green" : "red",
              color: "white",
            }}
          >
            {assetTelemetryData.onlineStatus ? "Online" : "Offline"}
          </Tag>
        </div>
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${dashboardDisplaySettings.metricsDashboardSettings.cols}, 1fr)`,
          gap: "6px",
          padding: "8px",
        }}
      >
        {assetTelemetryData ? (
          dashboardDisplaySettings.columns.map((item, idx) =>
            renderGridItem(item, idx)
          )
        ) : (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <Loading withOverlay={false} />
          </div>
        )}
      </div>
    </div>
  );
};
