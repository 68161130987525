import { InlineLoading } from "@carbon/react";
import { AssetDto } from "../../assets/assetsApi";
import { TelemetryDashboardType } from "../../monitoring/enums/TelemetryDashboardType.enum";
import { AssetGroupPfdDashboard } from "../asset-group-pfd-diagrams/AssetGroupPfdDashboard";
import {
  AssetGroupTelemetryDto,
  TelemetryDashboard,
} from "../assetGroupTelemetryApi";
import { AssetGroupTelemetryDataResponse } from "../dtos/asset-group-pfd.dto";

interface AssetGroupDashboardContentProps {
  assetGroupTelemetry: AssetGroupTelemetryDto;
  assetGroupTelemetryDataResponse: AssetGroupTelemetryDataResponse | null;
  assets: AssetDto[];
  dashboards: TelemetryDashboard[];
  isDarkMode: boolean;
}

export const AssetGroupDashboardContent = ({
  assetGroupTelemetryDataResponse,
  assetGroupTelemetry,
  assets,
  dashboards,
  isDarkMode = false,
}: AssetGroupDashboardContentProps) => {
  if (!dashboards.length || !assetGroupTelemetryDataResponse)
    return (
      <InlineLoading
        status="active"
        iconDescription="Loading"
        description="Loading telemetry data..."
        style={{ padding: "8px" }}
      />
    );

  const dashboard = dashboards[0];

  switch (dashboard.type) {
    case TelemetryDashboardType.ASSET_GROUP_PROCESS_FLOW_DIAGRAM:
      return (
        <AssetGroupPfdDashboard
          assetGroupTelemetry={assetGroupTelemetry}
          assetGroupTelemetryData={
            assetGroupTelemetryDataResponse.assetGroupTelemetryData
          }
          assets={assets}
          dashboards={dashboards}
          isDarkMode={isDarkMode}
        />
      );

    default:
      return null;
  }
};
