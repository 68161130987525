import { apiClient } from "../../components/api/apiClient";
import { AssetDto } from "../assets/assetsApi";
import { TelemetryDashboardType } from "./enums/TelemetryDashboardType.enum";

export interface AssetTelemetryDto {
  id: string;
}

export interface AssetTelemetryResponseDto {
  asset: AssetDto;
  telemetry: AssetTelemetryDto;
  dashboards: TelemetryDashboard[];
}

export interface TelemetryDashboard {
  id: string;
  name: string;
  displayName: string;
  type: TelemetryDashboardType;
  displaySettings?: any;
}

export interface TagValueData {
  id: string;
  tagId: string;
  label: string;
  tagValue: string;
  displayName: string;
  formattedValue: string;
  backgroundColor: string;
  textColor: string;
  lastUpdateTs: number;
}

export interface TelemetryTagsDataResponse {
  tags: TagValueData[];
  lastUpdatedAt: string;
  onlineStatus: boolean;
}

export interface TelemetryTagSettings {
  defaultTextColor: string;
  defaultTagBackgroundColor: string;
  defaultTagValueBackgroundColor: string;
  summarryTagIds: string[];
}

export interface TelemetryRecord {
  timestamp: string;
  value: number;
}

export interface TelemetryTagDto {
  id: string;
  tagId: string;
  label: string;
  tagValue: string;
  formattedValue: string;
  backgroundColor: string;
  textColor: string;
  lastUpdateTs: number;
}

export interface AssetTelemetrySummaryDto {
  asset: AssetDto;
  tags: TelemetryTagDto[];
  lastUpdatedAt: string;
}

export interface AggregatedHistoryValuesResponseDto {
  tagRecords: TelemetryRecord[];
}

export interface AssetTags {
  tags: TelemetryTagDto[];
  lastUpdatedAt: string;
}

export const telemetryApi = {
  async getAssetTelemetry(assetId: string): Promise<AssetTelemetryResponseDto> {
    const response = await apiClient.get(`/telemetry/asset/${assetId}`);
    return response.data;
  },

  async getAssetTelemetryData(
    telemetryId: string
  ): Promise<TelemetryTagsDataResponse> {
    const response = await apiClient.get(`/telemetry/${telemetryId}/values`);
    return response.data;
  },

  async getAggregatedTelemetryTagData(
    telemetryId: string,
    tagId: string
  ): Promise<AggregatedHistoryValuesResponseDto> {
    const response = await apiClient.get(
      `/telemetry/${telemetryId}/aggregated-tag-values?tagId=${tagId}`
    );

    return response.data;
  },

  async getAssetTelemetrySummary(
    telemetryId: string
  ): Promise<AssetTelemetrySummaryDto> {
    const response = await apiClient.get(`/telemetry/${telemetryId}/summary`);
    return response.data;
  },
};
