import PageTitle from "../../common/PageTitle";

export const FleetManagetmentHome = () => {
  return (
    <div>
      <PageTitle pageTitle={"Fleet Management"} />
      <div>
        <h4>Nothing to display</h4>
      </div>
    </div>
  );
};
