import { create } from "zustand";
import { UserDto } from "./usersApi";

interface UserState {
  user?: UserDto;
  updateCurrentUser: (user: UserDto) => void;
}

export const useUserStore = create<UserState>()((set) => ({
  user: undefined,
  updateCurrentUser: (user: UserDto) =>
    set((state) => ({ user: { ...user, ...state.user } })),
}));
