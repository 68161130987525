import { InlineLoading, Stack, Tag } from "@carbon/react";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { CustomBreadcrumb } from "../../common/CustomBreadcrumb";
import { formatDateWithTime } from "../../common/dates";
import { getTagUnit } from "../../common/telemetryUtils";
import {
  AssetTelemetryResponseDto,
  telemetryApi,
  TelemetryTagsDataResponse,
} from "../monitoring/telemetryApi";
import { AssetDashboard } from "./asset-dashboards/AssetDashboard";
import { POLLING_INTERVAL_MS } from "./asset-pfd-diagrams/AssetPfdDiagramDashboard";
import "./assetDetails.scss";

export const AssetDetailsPage = () => {
  const { assetId } = useParams();
  const [assetTelemetryData, setAssetTelemetryData] =
    useState<TelemetryTagsDataResponse | null>(null);
  const [assetDetails, setAssetDetails] =
    useState<AssetTelemetryResponseDto | null>(null);
  const isFetchingRef = useRef(false);

  useEffect(() => {
    if (assetId) {
      getAssetTelemetry(assetId);
    }
  }, []);

  useEffect(() => {
    if (assetDetails) {
      getAssetTelemetryValues();
    }

    const intervalId = setInterval(
      getAssetTelemetryValues,
      POLLING_INTERVAL_MS
    );
    return () => clearInterval(intervalId);
  }, [assetDetails]);

  const getAssetTelemetry = async (assetId: string) => {
    try {
      const assetTelemetryResponse = await telemetryApi.getAssetTelemetry(
        assetId
      );
      setAssetDetails(assetTelemetryResponse);
    } catch (e) {
      setAssetDetails(null);
    }
  };

  const getAssetTelemetryValues = async () => {
    if (!assetDetails || isFetchingRef.current) return;

    try {
      isFetchingRef.current = true;
      const assetTelemetryValuesResponse =
        await telemetryApi.getAssetTelemetryData(assetDetails.telemetry.id);
      setAssetTelemetryData(assetTelemetryValuesResponse);
    } catch (e) {
      isFetchingRef.current = false;
    } finally {
      isFetchingRef.current = false;
    }
  };

  const breadcrumbItems = [
    { label: "Monitoring", href: "/monitoring" },
    {
      label: assetDetails ? assetDetails?.asset.name : "Loading...",
      showLoading: !assetDetails || !assetTelemetryData,
      isCurrentPage: true,
    },
  ];

  function renderStatusTags() {
    if (assetTelemetryData && assetTelemetryData.tags) {
      return (
        <div className="status-container-scrollable" style={{ width: "500px" }}>
          {assetTelemetryData.tags.map((tag, index) => (
            <div key={index} className="status">
              <span className="status-type">{tag.tagId}</span>
              <span
                className="status-condition"
                style={{
                  backgroundColor: tag.backgroundColor,
                  color: "black",
                }}
              >
                {String(tag.formattedValue || tag.tagValue)}{" "}
                {String(tag.tagValue) === "true" ||
                String(tag.tagValue) === "false"
                  ? ""
                  : getTagUnit(tag.tagId)}
              </span>
            </div>
          ))}
        </div>
      );
    } else {
      return <InlineLoading />;
    }
  }

  return (
    <div style={{ height: "100%" }}>
      <CustomBreadcrumb items={breadcrumbItems} />

      {assetDetails ? (
        <div style={{ height: "100%" }}>
          <Stack orientation="horizontal" className="equipment-details-page">
            <Stack orientation="horizontal" gap="0">
              <div style={{ border: "1px solid grey", width: "150px" }}>
                <img
                  src={assetDetails?.asset.iconUrl || "-"}
                  alt="Asset Icon"
                />
              </div>
              <Stack className="asset-details">
                <div className="asset-name">{assetDetails?.asset.name}</div>
                <div className="asset-type">
                  {assetDetails?.asset.assetType.name}
                </div>
                <Tag size="sm">
                  Last update:{" "}
                  {assetTelemetryData
                    ? formatDateWithTime(assetTelemetryData.lastUpdatedAt)
                    : "-"}
                </Tag>
              </Stack>
            </Stack>

            {renderStatusTags()}
          </Stack>

          <AssetDashboard
            assetTelemetry={assetDetails.telemetry}
            assetTelemetryData={assetTelemetryData}
            asset={assetDetails.asset}
            dashboards={assetDetails.dashboards}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
