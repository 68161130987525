import {
  DashboardGridType,
  DashboardItem,
  ItemStateType,
  MetricStatus,
} from "../../monitoring/dtos/dashboard-display-settings.dto";
import { TagValueData } from "../../monitoring/telemetryApi";
import { DashboardStateCard } from "./DashboardStateCard";
import { DashboardSubGridCard } from "./DashboardSubGridCard";
import { HorizontalGroupCard } from "./HorizontalGroupCard";

interface VerticalStackCardProps {
  items: GridItemProps[];
  getTagByTagId: (tag: string) => TagValueData | undefined;
  miniChartEnabled?: boolean;
}

interface GridItemProps {
  type: any;
  tag?: string;
  tags?: string[];
  title: string;
  items: DashboardItem[];
  metricStatus: MetricStatus[];
  stateType?: ItemStateType;
  itemCols?: number;
  colSpan: number;
  rowSpan: number;
}

export const VerticalStackCard = ({
  items,
  getTagByTagId,
}: VerticalStackCardProps) => {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      {items.map((item, index) => (
        <div
          key={index}
          style={{
            height: `calc(( (100% - 8px )/ ${items.length})`,
            width: "100%",
            display: "flex",
            marginBottom: "8px",
          }}
        >
          {item.type === DashboardGridType.StateGroup && (
            <DashboardStateCard
              tags={item.items || []}
              title={item.title}
              itemCols={item.itemCols}
              getTagByTagId={getTagByTagId}
              stateType={item.stateType || ItemStateType.Boolean}
            />
          )}

          {item.type === DashboardGridType.HorizontalGroup && (
            <HorizontalGroupCard
              metrics={item.items as any}
              title={item.title}
              metricStatus={item.metricStatus}
              getTagByTagId={getTagByTagId}
            />
          )}

          {item.type === DashboardGridType.MetricsSubGrid && (
            <DashboardSubGridCard
              metrics={item.items as any}
              getTagByTagId={getTagByTagId}
              title={item.title}
              colSpan={item.colSpan}
              rowSpan={item.rowSpan}
            />
          )}
        </div>
      ))}
    </div>
  );
};
