import { Group, Rect, Text } from "react-konva";

const COLORS = {
  light: {
    warning: "#fa5252",
    active: "#8e7475",
    inactive: "white",
    highlight: "green",
    textWarning: "white",
    textActive: "white",
    textInactive: "lightGrey",
  },
  dark: {
    warning: "#fa5252",
    active: "#8e7475",
    inactive: "#4c4c4c",
    highlight: "green",
    textWarning: "white",
    textActive: "white",
    textInactive: "grey",
  },
};

export interface DiagramStateTagProps {
  radius: number;
  tagValue: boolean;
  x: number;
  y: number;
  tagKey: string;
  tagName?: string;
  warningStates?: string[];
  darkMode?: boolean;
}

export const DiagramStateTag = ({
  radius,
  tagValue,
  x,
  y,
  tagKey,
  tagName,
  warningStates = [],
  darkMode = false,
}: DiagramStateTagProps) => {
  const colorScheme = darkMode ? COLORS.dark : COLORS.light;
  const isWarning = warningStates.includes(tagKey);

  const backgroundColor =
    isWarning && tagValue
      ? colorScheme.warning
      : tagValue
      ? colorScheme.active
      : colorScheme.inactive;

  const textColor =
    isWarning && tagValue
      ? colorScheme.textWarning
      : tagValue
      ? colorScheme.textActive
      : colorScheme.textInactive;

  return (
    <Group key={tagKey} x={x} y={y}>
      <Rect
        width={radius * 7}
        height={radius * 2.8}
        fill={backgroundColor}
        cornerRadius={4}
        stroke="black"
        strokeWidth={0.5}
      />
      <Text
        text={tagName || tagKey}
        width={radius * 7}
        height={radius * 2.8}
        align="center"
        verticalAlign="middle"
        fill={textColor}
        fontSize={radius}
        strokeWidth={0.1}
      />
    </Group>
  );
};
