import { AppNavigator } from "./AppNavigator";
import { AppLoadingContainer } from "./components/AppLoadingContainer";

export const App = () => {
  return (
    <AppLoadingContainer>
      <AppNavigator />
    </AppLoadingContainer>
  );
};
