import { Route, Routes } from "react-router";
import { ReportHomePage } from "./ReportHomePage";

export const ReportNavigator = () => {
  return (
    <Routes>
      <Route index element={<ReportHomePage />} />
    </Routes>
  );
};
