import { Stack } from "@carbon/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { CustomBreadcrumb } from "../../common/CustomBreadcrumb";
import { CustomerDetailsGrid } from "./CustomerDetailsGrid";
import { CustomerUsersTable } from "./CustomerUsersTable";
import { PartnerCustomerDetailsDto, customersApi } from "./api/customersApi";

export const CustomerDetailsPage = () => {
  const { customerId } = useParams();
  const [partnerCustomer, setPartnerCustomer] =
    useState<PartnerCustomerDetailsDto | null>();

  if (!customerId) return <></>;

  useEffect(() => {
    getPartnerCustomer();
  }, []);

  const getPartnerCustomer = async () => {
    try {
      const partnerCustomerDetails = await customersApi.getPartnerCustomerById(
        customerId
      );

      setPartnerCustomer(partnerCustomerDetails);
    } catch (e) {
      setPartnerCustomer(null);
    }
  };

  const refreshCustomerUsersDetails = () => {
    getPartnerCustomer();
  };

  const breadcrumbItems = [
    { label: "Customers", href: "/customers" },
    {
      label: partnerCustomer ? partnerCustomer.customer.name : "Loading...",
      showLoading: !partnerCustomer,
      isCurrentPage: true,
    },
  ];

  return (
    <Stack>
      <CustomBreadcrumb items={breadcrumbItems} />

      {partnerCustomer ? (
        <>
          <CustomerDetailsGrid data={partnerCustomer} />
          <CustomerUsersTable
            data={partnerCustomer}
            refreshCustomerUsers={refreshCustomerUsersDetails}
          />
        </>
      ) : (
        <></>
      )}
    </Stack>
  );
};
