import { InlineLoading } from "@carbon/react";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/leaflet.css";
import _ from "lodash";
import { useEffect } from "react";
import { TileLayer } from "react-leaflet";
import { MapContainer } from "react-leaflet/MapContainer";
import { AssetGroupMarker } from "../asset-groups/AssetGroupMarker";
import { AssetGroupListDto } from "../asset-groups/assetGroupsApi";
import { AssetMarker } from "../assets/AssetMarker";
import { AssetListDto } from "../assets/assetsApi";

const DefaultIcon = new L.Icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [175, 0],
  shadowSize: [41, 41],
  shadowAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

export enum MapListType {
  ASSETS = "assets",
  ASSET_GROUPS = "assetGroups",
}

interface MapListViewProps {
  data: AssetListDto | AssetGroupListDto;
  type: MapListType;
}

export const MapListView = ({ data, type }: MapListViewProps) => {
  useEffect(() => {}, [data]);

  const findSiteById = (sites: any[], siteId: string) => {
    return _.find(sites, { id: siteId });
  };

  const findTypeById = (types: any[], typeId: string) => {
    return _.find(types, { id: typeId });
  };

  const groupBySite = (items: any[], key: string) => {
    return _.groupBy(items, key);
  };

  const renderMarkers = () => {
    let sites: any, items: any, types: any;

    switch (type) {
      case MapListType.ASSETS:
        ({ sites, assets: items, assetTypes: types } = data as AssetListDto);
        break;
      case MapListType.ASSET_GROUPS:
        ({
          sites,
          assetGroups: items,
          assetGroupTypes: types,
        } = data as AssetGroupListDto);
        break;
      default:
        return <InlineLoading />;
    }

    const itemsBySite = groupBySite(items, "siteId");

    const uniqueSiteIds = _.uniq(Object.keys(itemsBySite));

    return uniqueSiteIds.map((siteId) => {
      const site = findSiteById(sites, siteId);
      if (!site) return null;

      const itemsAtSite = itemsBySite[siteId];
      const itemTypes = itemsAtSite.map((item) =>
        findTypeById(types, item.assetTypeId)
      );

      switch (type) {
        case MapListType.ASSETS:
          return (
            <AssetMarker
              key={siteId}
              site={site}
              assets={itemsAtSite}
              itemTypes={itemTypes}
            />
          );
        case MapListType.ASSET_GROUPS:
          return (
            <AssetGroupMarker
              key={siteId}
              site={site}
              items={itemsAtSite}
              itemTypes={itemTypes}
            />
          );
        default:
          return null;
      }
    });
  };

  return (
    <MapContainer
      center={[37.0902, -95.7129]}
      zoom={4}
      maxZoom={20}
      minZoom={4}
      style={{ height: "calc(100vh - 360px)", width: "100%" }}
      dragging={true}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {renderMarkers()}
    </MapContainer>
  );
};
