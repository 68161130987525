import {
  Button,
  ButtonSet,
  FormLabel,
  InlineLoading,
  InlineNotification,
  StructuredListBody,
  StructuredListCell,
  StructuredListHead,
  StructuredListRow,
  StructuredListWrapper,
} from "@carbon/react";
import { useEffect, useState } from "react";
import { CreatePartnerCustomerDto } from "./api/customersApi";
import "./newCustomerConfirmation.scss";

interface NewCustomerConfirmationProps {
  formData: CreatePartnerCustomerDto;
  handleBack: any;
  handleSubmit: any;
  formError: string[];
}

export const NewCustomerConfirmation = ({
  formData,
  handleBack,
  handleSubmit,
  formError,
}: NewCustomerConfirmationProps) => {
  const [refreshError, setRefreshError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleConfirmation = async () => {
    setIsSubmitting(true);

    const response = await handleSubmit();

    if (response) {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    setRefreshError(!refreshError);
  }, [formError]);

  return (
    <div className="confirmation-container">
      <p
        className="text-sans cds-type--fluid-heading-03"
        dir="auto"
        style={{
          fontWeight: 400,
        }}
      >
        Confirm information
      </p>
      <FormLabel>Review your information carefully before finalising</FormLabel>

      <StructuredListWrapper isCondensed className="list">
        <StructuredListHead className="list-header">
          <StructuredListRow>
            <StructuredListCell>
              <p className="cds-type--fluid-heading-03">Company Information</p>
            </StructuredListCell>
            <StructuredListCell></StructuredListCell>
          </StructuredListRow>
        </StructuredListHead>
        <StructuredListBody>
          <StructuredListRow>
            <StructuredListCell noWrap>Company name</StructuredListCell>
            <StructuredListCell>{formData.name}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Country</StructuredListCell>
            <StructuredListCell>{formData.countryCode}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Street address</StructuredListCell>
            <StructuredListCell>{formData.streetAddress}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>City</StructuredListCell>
            <StructuredListCell>{formData.city}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Zip/Postal Code</StructuredListCell>
            <StructuredListCell>{formData.postalCode}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Phone number</StructuredListCell>
            <StructuredListCell>{formData.phoneNumber}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Company description</StructuredListCell>
            <StructuredListCell>{formData.description}</StructuredListCell>
          </StructuredListRow>
        </StructuredListBody>
      </StructuredListWrapper>

      <StructuredListWrapper isCondensed className="list">
        <StructuredListHead className="list-header">
          <StructuredListRow>
            <StructuredListCell>
              <p className="cds-type--fluid-heading-03">
                Customer&apos;s Primary Contact
              </p>
            </StructuredListCell>
            <StructuredListCell></StructuredListCell>
          </StructuredListRow>
        </StructuredListHead>
        <StructuredListBody>
          <StructuredListRow>
            <StructuredListCell noWrap>First name</StructuredListCell>
            <StructuredListCell>
              {formData.primaryUser.firstName}
            </StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Last Name</StructuredListCell>
            <StructuredListCell>
              {formData.primaryUser.lastName}
            </StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Position</StructuredListCell>
            <StructuredListCell>
              {formData.primaryUser.position}
            </StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Email</StructuredListCell>
            <StructuredListCell>
              {formData.primaryUser.email}
            </StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Phone Number</StructuredListCell>
            <StructuredListCell>
              {formData.primaryUser.phoneNumber}
            </StructuredListCell>
          </StructuredListRow>
        </StructuredListBody>
      </StructuredListWrapper>

      {formError && formError.length > 0 ? (
        formError.map((error) => (
          <InlineNotification
            key={`${error}-${refreshError}`}
            subtitle={error}
            title="Error message"
          />
        ))
      ) : (
        <></>
      )}

      <ButtonSet className="form-button-group">
        {isSubmitting ? (
          <InlineLoading
            description={"Confirming..."}
            style={{ width: "120px" }}
          />
        ) : (
          <Button onClick={handleConfirmation}>Confirm</Button>
        )}

        <Button disabled={isSubmitting} kind="tertiary" onClick={handleBack}>
          Back
        </Button>
      </ButtonSet>
    </div>
  );
};
