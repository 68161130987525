import { useEffect, useRef, useState } from "react";
import { Group, Rect, Text } from "react-konva";
import { formatTagValue, getTagUnit } from "../../../common/telemetryUtils";
import { POLLING_INTERVAL_MS } from "../../../routes/asset-groups/asset-group-pfd-diagrams/AssetGroupPfdDashboard";
import { telemetryApi } from "../../../routes/monitoring/telemetryApi";

export interface DiagramRectTagProps {
  radius: number;
  backgroundColor: string;
  tagValue: string;
  x: number;
  y: number;
  tagKey: string;
  telemetryId: string;
  isRateValue?: boolean;
}

export const DiagramRectTag = ({
  radius,
  backgroundColor,
  tagValue,
  x,
  y,
  tagKey,
  telemetryId,
  isRateValue,
}: DiagramRectTagProps) => {
  const [tempBackgroundColor, setTempBackgroundColor] =
    useState<string>(backgroundColor);
  const [showToolTip, setShowToolTip] = useState(false);

  const [latestAggregatedTagValue, setLatestAggregatedTagValue] = useState<
    string | null
  >(null);

  const isFetchingRef = useRef(false);

  const getAggregatedValues = async () => {
    if (isRateValue && !isFetchingRef.current) {
      try {
        isFetchingRef.current = true;
        await getRateValue();
      } catch (error) {
        isFetchingRef.current = false;
      } finally {
        isFetchingRef.current = false;
      }
    }
  };

  useEffect(() => {
    getAggregatedValues();
    const intervalId = setInterval(getAggregatedValues, POLLING_INTERVAL_MS);
    return () => clearInterval(intervalId);
  }, [tagKey]);

  useEffect(() => {
    if (tagValue !== "-") {
      setTempBackgroundColor("green");
      const timeoutId = setTimeout(() => {
        setTempBackgroundColor(backgroundColor);
      }, 150);

      return () => clearTimeout(timeoutId);
    }
  }, [tagValue, latestAggregatedTagValue, backgroundColor]);

  const displayValue = () => {
    if (tagValue === "-") {
      return "-";
    }

    let numericValue = parseFloat(tagValue);

    if (isRateValue) {
      const parsedAggregatedValue = latestAggregatedTagValue
        ? parseFloat(latestAggregatedTagValue)
        : NaN;

      if (!isNaN(parsedAggregatedValue)) {
        numericValue = parsedAggregatedValue;
      }
    }

    if (!isNaN(numericValue)) {
      const unit = getTagUnit(tagKey);
      const formattedValue = Number.isInteger(numericValue)
        ? numericValue.toFixed(0)
        : numericValue.toFixed(2);

      return `${formattedValue} ${unit}${isRateValue ? "/d" : ""}`;
    } else {
      return "-";
    }
  };

  const getRateValue = async () => {
    try {
      if (isRateValue && telemetryId && isRateValue) {
        const response = await telemetryApi.getAggregatedTelemetryTagData(
          telemetryId,
          tagKey
        );

        setLatestAggregatedTagValue(
          response.tagRecords[response.tagRecords.length - 1].value.toFixed(2)
        );
      }
    } catch (e) {
      setLatestAggregatedTagValue("-");
    }
  };

  return (
    <Group
      key={tagKey}
      x={x}
      y={y}
      onMouseOver={() => setShowToolTip(true)}
      onMouseLeave={() => setShowToolTip(false)}
    >
      <Rect
        width={radius * 8}
        height={radius * 2.1}
        fill={tempBackgroundColor}
        cornerRadius={4}
        stroke={"black"}
        strokeWidth={0.5}
      />

      <Text
        text={`${displayValue()}`}
        width={radius * 8}
        height={radius * 2.1}
        align="center"
        verticalAlign="middle"
        fill={"white"}
        fontSize={radius * 1}
      />

      <Group visible={showToolTip}>
        <Rect
          fill="white"
          width={radius * 8}
          height={radius * 2.1}
          stroke="black"
          strokeWidth={1}
          cornerRadius={4}
        />
        <Text
          text={`${tagKey}`}
          width={radius * 4}
          height={radius * 2}
          fontSize={radius * 0.9}
          align="center"
          verticalAlign="middle"
        />
        <Text
          text={`${formatTagValue(
            tagValue,
            tagKey.includes("flow") ? 2 : 0
          )} ${getTagUnit(tagKey)}`}
          width={radius * 4}
          x={(radius * 8) / 2.1}
          height={radius * 2.1}
          fontSize={radius * 0.9}
          align="center"
          verticalAlign="middle"
        />
      </Group>
    </Group>
  );
};
