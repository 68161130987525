import axios from "axios";
import { FC, ReactNode, useEffect, useState } from "react";
import { authApi } from "../routes/auth/authApi";
import { useUserStore } from "../routes/users/userStore";
import { usersApi } from "../routes/users/usersApi";

interface AppLoadingProps {
  children: ReactNode;
}

export enum LoadingState {
  NotStarted = 1,
  InProgress = 2,
  Succeeded = 3,
  Failed = 4,
}

export const AppLoadingContainer: FC<AppLoadingProps> = ({ children }) => {
  const [appLoading, setAppLoading] = useState<LoadingState>(
    LoadingState.NotStarted
  );
  const updateCurrentUser = useUserStore((state) => state.updateCurrentUser);

  useEffect(() => {
    setAppLoading(LoadingState.InProgress);

    if (authApi.getUserSession()) {
      loadUserProfile();
    } else {
      setTimeout(() => {
        setAppLoading(LoadingState.Succeeded);
      }, 250);
    }
  }, []);

  const loadUserProfile = async () => {
    const response = await usersApi.getProfile();
    if (!axios.isAxiosError(response)) {
      updateCurrentUser(response);
      setTimeout(() => {
        setAppLoading(LoadingState.Succeeded);
      }, 250);
    } else {
      setTimeout(() => {
        setAppLoading(LoadingState.Succeeded);
      }, 250);
    }
  };

  switch (appLoading) {
    case LoadingState.Succeeded:
      return <>{children}</>;
    case LoadingState.InProgress:
    case LoadingState.NotStarted:
      return <></>;
    case LoadingState.Failed:
      return <>{children}</>;
    default:
      return <>Failed to load the app</>;
  }
};
