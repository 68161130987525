import { UserAvatar } from "@carbon/icons-react";
import { Stack } from "@carbon/react";
import { PartnerCustomerDetailsDto } from "./api/customersApi";
import "./customerDetailsGrid.scss";

interface CustomerDetailsGridProps {
  data: PartnerCustomerDetailsDto;
}

export const CustomerDetailsGrid = ({ data }: CustomerDetailsGridProps) => {
  const getPrimaryContact = () => {
    return data.users.find((user) => user.id == data.primaryAdminUserId);
  };

  const primaryContact = getPrimaryContact();
  const customer = data.customer;

  return (
    <Stack orientation="horizontal" className="customer-details-grid">
      <div className="grid-column has-grid-divider">
        <img
          style={{ height: "80px", width: "80px", background: "grey" }}
          src={customer.logoUrl}
          alt="customer logo"
        />
        <p className="cds--type-fluid-heading-04">{customer.name}</p>
      </div>
      <Stack className="grid-column has-grid-divider">
        <div>
          <p className="cds--type-heading-compact-02">{customer.description}</p>
          <p className="cds--type-body-01">{customer.address.streetAddress}</p>
          <p className="cds--type-body-01">{customer.address.city}</p>
          <p className="cds--type-body-01">{customer.phoneNumber}</p>
        </div>
      </Stack>
      <Stack className="grid-column">
        <div>
          <p className="cds--type-heading-compact-02 customer-grid-title">
            Primary contact
          </p>

          <Stack orientation="horizontal">
            <UserAvatar size="30px" />
            <Stack>
              <p className="cds--type-body-01">{primaryContact?.displayName}</p>
              <p className="cds--type-body-01">{primaryContact?.email}</p>
              <p className="cds--type-body-01">{primaryContact?.position}</p>
            </Stack>
          </Stack>
        </div>
      </Stack>
    </Stack>
  );
};
