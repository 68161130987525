import { Route, Routes } from "react-router";
import { AddUserPage } from "./AddUserPage";
import { EditUserPage } from "./EditUserPage";
import { UsersPage } from "./UsersPage";

export const UsersNavigator = () => {
  return (
    <Routes>
      <Route index element={<UsersPage />} />
      <Route path="add-user" element={<AddUserPage />} />
      <Route path=":userId/edit" element={<EditUserPage />} />
    </Routes>
  );
};
