// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { AddFilled, UserAvatar } from "@carbon/icons-react";
import {
  Button,
  DataTable,
  DataTableSkeleton,
  Modal,
  OverflowMenu,
  OverflowMenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from "@carbon/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { PartnerUserDto, usersApi } from "./usersApi";

const headers = [
  {
    key: "displayName",
    header: "Name",
  },
  {
    key: "mainRole",
    header: "Role",
  },
  {
    key: "email",
    header: "Email",
  },
  {
    key: "position",
    header: "Position",
  },
];

export const UsersList = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [users, setUsers] = useState<PartnerUserDto[] | null>(null);
  const [deleteModalOpened, setDeleteModelOpened] = useState<boolean>(false);
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      const userResponse = await usersApi.getPartnerUsers();
      const updatedUsers = userResponse.users.map((user) => ({
        ...user,
        mainRole: user.role.displayName,
      }));
      setUsers(updatedUsers);
    } catch (e) {
      setUsers(null);
    }
  };

  const handleDeleteAction = (userId: string) => {
    setDeleteModelOpened(true);
    setUserIdToDelete(userId);
  };

  const handleDeleteUser = async () => {
    const deleteUserResponse = await usersApi.deletePartnerUser(userIdToDelete);
    if (deleteUserResponse) {
      getUsers();
    }

    setDeleteModelOpened(false);
  };

  if (!users) {
    return <DataTableSkeleton headers={headers} />;
  }

  return (
    <DataTable rows={users || []} headers={headers}>
      {({ rows, headers, getTableProps, getToolbarProps, getHeaderProps }) => (
        <TableContainer>
          <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
            <TableToolbarContent>
              <Button
                onClick={() => navigate("add-user")}
                renderIcon={AddFilled}
              >
                Add User
              </Button>
            </TableToolbarContent>
          </TableToolbar>

          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                <TableHeader></TableHeader>
                {headers.map((header) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableHeader
                    {...getHeaderProps({
                      header,
                      isSortable: true,
                    })}
                    key={header.key}
                  >
                    {header.header}
                  </TableHeader>
                ))}
                <TableHeader></TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <UserAvatar size={32} />
                  </TableCell>
                  {row.cells.map((cell) => {
                    return <TableCell key={cell.id}>{cell.value}</TableCell>;
                  })}

                  <TableCell className="cds--table-column-menu">
                    <OverflowMenu flipped>
                      <OverflowMenuItem
                        itemText="Edit"
                        onClick={() => navigate(`${row.id}/edit`)}
                      />
                      <OverflowMenuItem
                        itemText="Delete"
                        onClick={() => handleDeleteAction(row.id)}
                      />
                    </OverflowMenu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Modal
            open={deleteModalOpened}
            onRequestClose={() => setDeleteModelOpened(false)}
            onRequestSubmit={() => handleDeleteUser()}
            onSecondarySubmit={() => setDeleteModelOpened(false)}
            danger
            modalHeading="By deleting the user all data related to the user will be lost"
            modalLabel="Are you sure you want to delete the user?"
            primaryButtonText="Delete"
            secondaryButtonText="Cancel"
          />
        </TableContainer>
      )}
    </DataTable>
  );
};
