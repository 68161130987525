import { InlineLoading } from "@carbon/react";
import { TelemetryDashboardType } from "../../monitoring/enums/TelemetryDashboardType.enum";
import {
  AssetTelemetryDto,
  TelemetryDashboard,
  TelemetryTagsDataResponse,
} from "../../monitoring/telemetryApi";
import { DashboardPage } from "../asset-metrics-dashboards/DashboardPage";
import { AssetPfdDiagramDashboard } from "../asset-pfd-diagrams/AssetPfdDiagramDashboard";
import { AssetPidDiagramDashboard } from "../asset-pid-diagrams/AssetPidDiagramDashboard";
import { AssetDto } from "../assetsApi";

interface AssetDashboardContentProps {
  assetTelemetry: AssetTelemetryDto;
  assetTelemetryData: TelemetryTagsDataResponse | null;
  asset: AssetDto;
  dashboards: TelemetryDashboard[];
}

export const AssetDashboardContent = ({
  assetTelemetry,
  assetTelemetryData,
  asset,
  dashboards,
}: AssetDashboardContentProps) => {
  if (!dashboards.length || !assetTelemetryData)
    return (
      <InlineLoading
        status="active"
        iconDescription="Loading"
        description="Loading telemetry data..."
        style={{ padding: "8px" }}
      />
    );

  const dashboard = dashboards[0];

  switch (dashboard.type) {
    case TelemetryDashboardType.PROCESS_FLOW_DIAGRAM:
      return (
        <AssetPfdDiagramDashboard
          assetTelemetry={assetTelemetry}
          assetTelemetryData={assetTelemetryData}
          asset={asset}
          dashboard={dashboards[0]}
        />
      );
    case TelemetryDashboardType.P_AND_ID_DIAGRAM:
      return (
        <AssetPidDiagramDashboard
          assetTelemetry={assetTelemetry}
          assetTelemetryData={assetTelemetryData}
          asset={asset}
          dashboards={dashboards}
        />
      );
    case TelemetryDashboardType.METRICS_DASHBOARD:
      return (
        <DashboardPage
          assetTelemetry={assetTelemetry}
          assetTelemetryData={assetTelemetryData}
          asset={asset}
          dashboards={dashboards}
        />
      );
    default:
      return null;
  }
};
