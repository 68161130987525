import { Stack } from "@carbon/react";
import { useEffect, useState } from "react";
import { formatToLocaleDateTime } from "../../../common/dates";
import { formatTagValue, getTagUnit } from "../../../common/telemetryUtils";
import { DiagramDashboardDisplaySettingsDto } from "../../monitoring/dtos/dashboard-display-settings.dto";
import {
  TelemetryDashboard,
  TelemetryTagsDataResponse,
} from "../../monitoring/telemetryApi";

export interface AssetPidDiagramDashboardSidebarProps {
  dashboards: TelemetryDashboard[];
  telemetryTagsData: TelemetryTagsDataResponse;
}

export const AssetPidDiagramDashboardSidebar = ({
  dashboards,
  telemetryTagsData,
}: AssetPidDiagramDashboardSidebarProps) => {
  const [diagramTelemetryTags, setDiagramTelemetryTags] =
    useState<TelemetryTagsDataResponse>();

  useEffect(() => {
    setDiagramTelemetryTags(telemetryTagsData);
  }, [telemetryTagsData]);

  if (!diagramTelemetryTags) {
    return <Stack>No telemetry data found.</Stack>;
  }

  const findRelatedTags = (key: string) => {
    return diagramTelemetryTags?.tags.filter((tag) =>
      tag.tagId.startsWith(key)
    );
  };

  return (
    <div style={{ padding: "6px 8px" }}>
      {dashboards?.map((dashboard, index) => (
        <div
          key={index}
          style={{ border: "1px solid lightGrey", marginBottom: "6px" }}
        >
          <h5 style={{ padding: "2px 16px", margin: "6px 0px" }}>
            {dashboard.displayName}
          </h5>
          {Object.entries(
            (dashboard.displaySettings as DiagramDashboardDisplaySettingsDto)
              .telemetryPoints
          ).map(([tagId, index]) => {
            const relatedTags = findRelatedTags(tagId);
            return (
              <div
                key={`${tagId}-${index}-points`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "4px 16px",
                  borderBottom: "0.5px solid lightGrey",
                }}
              >
                <Stack>
                  {relatedTags.length > 1 ? (
                    relatedTags.map((tag, index) => (
                      <span key={`${tag.tagId}-${index}-tagId`}>
                        {tag.tagId}
                      </span>
                    ))
                  ) : (
                    <span>{tagId}</span>
                  )}
                </Stack>

                <Stack>
                  {relatedTags.length > 0 ? (
                    relatedTags.map((tag, index) => (
                      <span key={`${tag.tagId}-${index}-unit`}>
                        {formatTagValue(
                          tag.tagValue,
                          tag.tagId.toLowerCase().includes("flow") ? 2 : 0
                        )}{" "}
                        {getTagUnit(tag.tagId)}
                      </span>
                    ))
                  ) : (
                    <span>-</span>
                  )}
                </Stack>
              </div>
            );
          })}
        </div>
      ))}

      <div style={{ margin: "4px 16px", height: "200px" }}>
        <strong>Last updated:</strong>{" "}
        {diagramTelemetryTags?.lastUpdatedAt
          ? formatToLocaleDateTime(diagramTelemetryTags?.lastUpdatedAt)
          : "-"}
      </div>
    </div>
  );
};
