import { MetricStatus } from "../../monitoring/dtos/dashboard-display-settings.dto";
import { TagValueData } from "../../monitoring/telemetryApi";
import classes from "./DashboardPage.module.css";

interface HorizontalGroupCardProps {
  metrics: MetricProps[];
  title?: string;
  getTagByTagId: (tag: string) => TagValueData | undefined;
  metricStatus?: MetricStatus[];
}

interface MetricProps {
  title: string;
  unit: string;
  tag: string;
  rowSpan: boolean;
}

export const HorizontalGroupCard = ({
  metrics,
  title,
  getTagByTagId,
  metricStatus,
}: HorizontalGroupCardProps) => {
  const renderMetricStatus = (status: MetricStatus[]) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        flexDirection: "column",
      }}
    >
      {status.map((metric, index) => (
        <div
          key={index}
          style={{
            fontSize: "0.875rem",
            color: "white",
            textAlign: "center",
            padding: "0.5rem",
            border: "1px solid darkGrey",
            minWidth: "100px",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              fontSize: "0.75rem",
            }}
          >
            {metric.description || "-"}{" "}
          </span>
          <span style={{ color: "white", fontSize: "0.75rem" }}>
            {getTagByTagId(metric.tagId)?.tagValue || "-"}
          </span>
        </div>
      ))}
    </div>
  );

  const renderMetrics = () => (
    <div style={{ display: "flex", height: "calc(100% - 50px)" }}>
      {metrics.map((metric, index) => {
        const tagData = getTagByTagId(metric.tag);
        return (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: `calc(100% / ${metrics.length})`,
            }}
          >
            <span
              style={{
                padding: "0 0.5rem",
                fontSize: "2rem",
                fontWeight: "bold",
                color: "white",
              }}
              className={classes.dashboardGridCardValue}
            >
              {tagData?.tagValue || "-"}{" "}
              <span style={{ fontSize: "1.5rem", lineHeight: "2" }}>
                {metric.unit}
              </span>
            </span>

            <span
              style={{
                fontSize: "0.875rem",
                letterSpacing: "1px",
                color: "grey",
              }}
            >
              {metric.tag}
            </span>
          </div>
        );
      })}
    </div>
  );

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        background: "rgb(25, 24, 24)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {title && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ padding: "8px" }}>{title}</div>

          {metricStatus && renderMetricStatus(metricStatus)}
        </div>
      )}
      {renderMetrics()}
    </div>
  );
};
