import { CheckmarkFilled } from "@carbon/icons-react";
import { Button, ButtonSet } from "@carbon/react";
import { useNavigate } from "react-router";
import "./userCreationSuccess.scss";

interface UserCreationSuccessfulProps {
  resetState: any;
}

const UserCreationSuccess = ({ resetState }: UserCreationSuccessfulProps) => {
  const navigate = useNavigate();

  return (
    <div className="user-creation-success">
      <CheckmarkFilled size="40" color="#24A148" />
      <p className="cds--type-fluid-heading-04">User added successfully</p>
      <p className="cds--type-fluid-heading-03">
        We have emailed the user all the details the need to get started.
      </p>
      <ButtonSet className="form-button-group">
        <Button kind="tertiary" onClick={resetState}>
          Add another user
        </Button>
        <Button kind="tertiary" onClick={() => navigate("/users")}>
          View Users
        </Button>
      </ButtonSet>
    </div>
  );
};

export default UserCreationSuccess;
