import { Renew } from "@carbon/icons-react";
import { Button, ContentSwitcher, Loading, Stack, Switch } from "@carbon/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import PageTitle from "../../common/PageTitle";
import "../../common/scss/loading.scss";
import { AssetGroupList } from "../asset-groups/AssetGroupList";
import {
  AssetGroupListDto,
  assetGroupsApi,
} from "../asset-groups/assetGroupsApi";
import { AssetListCardView } from "../assets/AssetListCardView";
import { AssetListDto, assetsApi } from "../assets/assetsApi";
import { MapListType, MapListView } from "./MapListView";
import "./monitoring.scss";

const CARD_VIEW_INDEX = 0;
const MAP_VIEW_INDEX = 1;
const MAP_VIEW_PATH = "/monitoring/map";
const CARD_VIEW_PATH = "/monitoring/card";
const ASSET_VIEW_INDEX = 0;

export const MonitoringHomePage = () => {
  const location = useLocation();
  const initialIndex = location.pathname.includes(MAP_VIEW_PATH)
    ? MAP_VIEW_INDEX
    : CARD_VIEW_INDEX;
  const [selectedView, setSelectedView] = useState(initialIndex);
  const [selectedAssetType, setSelectedAssetType] = useState(ASSET_VIEW_INDEX);
  const [assetList, setAssetList] = useState<AssetListDto | null>(null);
  const [assetGroupList, setAssetGroupList] =
    useState<AssetGroupListDto | null>(null);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.includes(MAP_VIEW_PATH)) {
      setSelectedView(MAP_VIEW_INDEX);
    } else {
      setSelectedView(CARD_VIEW_INDEX);
    }
    getAssetList();
    getAssetGroupList();
  }, [location.pathname]);

  const getAssetList = async () => {
    const assetList = await assetsApi.getAssetList();
    setAssetList(assetList);
  };

  const getAssetGroupList = async () => {
    const assetGroupList = await assetGroupsApi.getAssetGroupList();
    setAssetGroupList(assetGroupList);
  };

  const refreshAssetList = () => {
    getAssetList();
    setRefreshFlag(!refreshFlag);
  };

  const renderContent = () => {
    if (!assetList || !assetGroupList) {
      return (
        <div className="loading-container">
          <Loading withOverlay={false} />
        </div>
      );
    }

    if (selectedView === CARD_VIEW_INDEX) {
      return selectedAssetType === ASSET_VIEW_INDEX ? (
        <AssetListCardView assetList={assetList} refreshFlag={refreshFlag} />
      ) : (
        <AssetGroupList
          assetGroupList={assetGroupList}
          refreshFlag={refreshFlag}
        />
      );
    }

    return selectedAssetType === ASSET_VIEW_INDEX ? (
      <MapListView data={assetList} type={MapListType.ASSETS} />
    ) : (
      <MapListView data={assetGroupList} type={MapListType.ASSET_GROUPS} />
    );
  };

  const handleSwitchChange = (event: any) => {
    setSelectedView(event.index);
    if (event.index === CARD_VIEW_INDEX) {
      navigate(CARD_VIEW_PATH);
    } else {
      navigate(MAP_VIEW_PATH);
    }
  };

  const handleAssetTypeSwitchChange = (event: any) => {
    setSelectedAssetType(event.index);
  };

  return (
    <Stack>
      <PageTitle pageTitle={"Monitoring"} />

      <div className="switcher-container">
        <ContentSwitcher
          onChange={handleSwitchChange}
          selectedIndex={selectedView}
          size="md"
          className="content-switcher"
        >
          <Switch text="Card View" className="switch-selected" />
          <Switch text="Map View" className="switch-selected" />
        </ContentSwitcher>
      </div>

      <div className="monitoring-container">
        <Stack orientation="horizontal" className="assets-switcher-container">
          <div>
            <ContentSwitcher
              onChange={handleAssetTypeSwitchChange}
              selectedIndex={selectedAssetType}
              size="md"
              className="assets-switcher"
            >
              <Switch name="asset" text="Assets" className="switch-selected" />
              <Switch
                name="group"
                text="Asset Groups"
                className="switch-selected"
              />
            </ContentSwitcher>
          </div>

          <div className="button-container">
            <Button
              kind="ghost"
              renderIcon={Renew}
              iconDescription="Refresh"
              hasIconOnly
              onClick={refreshAssetList}
            />
          </div>
        </Stack>

        {renderContent()}
      </div>
    </Stack>
  );
};
