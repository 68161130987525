import {
  Button,
  ButtonSet,
  FormLabel,
  InlineLoading,
  InlineNotification,
  StructuredListBody,
  StructuredListCell,
  StructuredListRow,
  StructuredListWrapper,
} from "@carbon/react";
import { useState } from "react";
import { useRoleStore } from "./roleStore";

interface AddUserConfirmationListProps {
  formData: {
    firstName: string;
    lastName: string;
    position: string;
    phoneNumber: string;
    email: string;
    roleId: string;
  };
  handleSubmit: any;
  handleBack: any;
  formErrors: string[];
}

export const AddUserConfirmationList = ({
  formData,
  formErrors,
  handleBack,
  handleSubmit,
}: AddUserConfirmationListProps) => {
  const availableRoles = useRoleStore((state) => state.roles);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleConfirmation = async () => {
    setIsSubmitting(true);
    const response = await handleSubmit();

    if (response) {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="confirmation-container">
      <div style={{ marginBottom: "32px" }}>
        <p
          className="text-sans"
          dir="auto"
          style={{
            fontWeight: 400,
            padding: 0,
            margin: 0,
          }}
        >
          Confirm information
        </p>
        <FormLabel>
          Review the information carefully before confirming
        </FormLabel>
      </div>

      <StructuredListWrapper>
        <StructuredListBody>
          <StructuredListRow>
            <StructuredListCell noWrap>First name</StructuredListCell>
            <StructuredListCell>{formData.firstName}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Last Name</StructuredListCell>
            <StructuredListCell>{formData.lastName}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Position</StructuredListCell>
            <StructuredListCell>{formData.position}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Email</StructuredListCell>
            <StructuredListCell>{formData.email}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Phone Number</StructuredListCell>
            <StructuredListCell>{formData.phoneNumber}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Role</StructuredListCell>
            <StructuredListCell>
              {availableRoles &&
                availableRoles.find((role) => role.id === formData.roleId)
                  ?.displayName}
            </StructuredListCell>
          </StructuredListRow>
        </StructuredListBody>
      </StructuredListWrapper>

      {formErrors.length > 0 ? (
        formErrors.map((error, index) => (
          <InlineNotification
            key={index}
            subtitle={error}
            title="Error message"
          />
        ))
      ) : (
        <></>
      )}

      <ButtonSet className="form-button-group">
        {isSubmitting ? (
          <InlineLoading
            description={"Confirming..."}
            style={{ width: "120px" }}
          />
        ) : (
          <Button onClick={handleConfirmation}>Confirm</Button>
        )}

        <Button disabled={isSubmitting} kind="tertiary" onClick={handleBack}>
          Back
        </Button>
      </ButtonSet>
    </div>
  );
};
