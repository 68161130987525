import { Button, Loading } from "@carbon/react";
import { useEffect, useState } from "react";
import { AssetDto } from "../../assets/assetsApi";
import { DiagramDashboardDisplaySettingsDto } from "../../monitoring/dtos/dashboard-display-settings.dto";
import {
  AssetTelemetryDto,
  TelemetryDashboard,
  TelemetryTagsDataResponse,
  TelemetryTagSettings,
} from "../../monitoring/telemetryApi";
import { AssetPfdDiagram } from "./AssetPfdDiagram";
import { AssetPfdDiagramPageSidebar } from "./AssetPfdDiagramPageSidebar";

interface AssetPfdDiagramDashboardProps {
  assetTelemetry: AssetTelemetryDto;
  assetTelemetryData: TelemetryTagsDataResponse;
  asset: AssetDto;
  dashboard: TelemetryDashboard;
}

export const POLLING_INTERVAL_MS = 5000;

export const AssetPfdDiagramDashboard = ({
  assetTelemetryData,
  asset,
  dashboard,
}: AssetPfdDiagramDashboardProps) => {
  const [telemetryTagsSettings, setTelemetryTagSettings] =
    useState<TelemetryTagSettings>();

  useEffect(() => {
    setupDiagramSettings();
  }, [dashboard]);

  const setupDiagramSettings = () => {
    if (dashboard) {
      setTelemetryTagSettings(dashboard.displaySettings.telemetryTagSettings);
    }
  };

  const renderTopBarButtonGroup = () => (
    <Button>{dashboard.displayName}</Button>
  );

  const renderFullScreenTopBar = () => (
    <div
      style={{
        display: "flex",
        padding: "8px",
        width: "100%",
        borderBottom: "1px solid lightGrey",
        marginBottom: "8px",
      }}
    >
      <div
        style={{
          borderRight: "1px solid lightGrey",
          padding: "12px",
          marginRight: "12px",
        }}
      >
        {asset.name}
      </div>
      {renderTopBarButtonGroup()}
    </div>
  );

  const renderContent = () => {
    if (!dashboard || !assetTelemetryData) {
      return <LoadingContainer />;
    }

    return (
      <div style={{ display: "flex", height: "100%" }}>
        <div
          style={{
            width: "300px",
            maxHeight: "calc(100vh - 300px)",
            padding: "8px",
          }}
        >
          <AssetPfdDiagramPageSidebar
            diagramTelemetryTagsData={assetTelemetryData}
            dashboard={dashboard}
          />
        </div>

        <div
          style={{
            display: "flex",
            width: "calc(100% - 300px)",
            justifyContent: "center",
          }}
        >
          <AssetPfdDiagram
            displaySettings={
              dashboard.displaySettings as DiagramDashboardDisplaySettingsDto
            }
            tagSettings={telemetryTagsSettings!}
            telemetryTagsData={assetTelemetryData.tags}
          />
        </div>
      </div>
    );
  };

  const LoadingContainer = () => (
    <div
      style={{
        width: "100%",
        height: "300px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loading withOverlay={false} />
    </div>
  );

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {renderFullScreenTopBar()}
      {renderContent()}
    </div>
  );
};
