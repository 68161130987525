import { ConnectionSignalOff } from "@carbon/icons-react";
import { useEffect, useRef, useState } from "react";
import { formatDashboardNumber } from "../../../common/telemetryUtils";
import { AssetTelemetryDto, telemetryApi } from "../../monitoring/telemetryApi";
import { POLLING_INTERVAL_MS } from "../asset-pfd-diagrams/AssetPfdDiagramDashboard";
import classes from "./DashboardPage.module.css";
import {
  SENSOR_DISCONNECT_VALUES,
  SensorType,
  TelemetryType,
} from "./telemetry-types.enum";

interface DashboardMetricsCardProps {
  title: string;
  unit: string;
  tagId: string;
  value: string;
  description: string;
  aggregationUnit?: string;
  assetTelemetry: AssetTelemetryDto;
}

const formatValue = (value: string, decimalPoints: number) => {
  const numericValue = parseFloat(value);
  return isNaN(numericValue)
    ? "-"
    : Number.isInteger(numericValue)
    ? formatDashboardNumber(numericValue)
    : numericValue.toFixed(decimalPoints);
};

export const DashboardMetricsCard = ({
  title,
  unit,
  value,
  tagId,
  description,
  aggregationUnit,
  assetTelemetry,
}: DashboardMetricsCardProps) => {
  const [latestAggregatedTagValue, setLatestAggregatedTagValue] = useState<
    string | null
  >(null);
  const isFetchingRef = useRef(false);

  const fetchAggregatedValues = async () => {
    if (aggregationUnit && !isFetchingRef.current) {
      isFetchingRef.current = true;
      try {
        const data = await telemetryApi.getAggregatedTelemetryTagData(
          assetTelemetry.id,
          tagId
        );
        setLatestAggregatedTagValue(
          data.tagRecords[data.tagRecords.length - 1].value.toFixed(2)
        );
      } finally {
        isFetchingRef.current = false;
      }
    }
  };

  useEffect(() => {
    fetchAggregatedValues();
    const intervalId = setInterval(fetchAggregatedValues, POLLING_INTERVAL_MS);
    return () => clearInterval(intervalId);
  }, [tagId]);

  const formattedValue = formatValue(value, 2);
  const displayValue =
    aggregationUnit && latestAggregatedTagValue
      ? latestAggregatedTagValue
      : formatValue(value, 0);

  return (
    <div
      style={{
        height: "calc(100%)",
        width: "100%",
        background: "rgb(25, 24, 24)",
        minHeight: "150px",
      }}
    >
      <div style={{ padding: "8px" }}>{title}</div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "calc(100% - 50px)",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          {unit === TelemetryType.Celsius &&
          formattedValue ===
            SENSOR_DISCONNECT_VALUES[SensorType.Temperature] ? (
            <ConnectionSignalOff size="72" />
          ) : (
            <>
              <span
                style={{
                  padding: "0 0.5rem",
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: "white",
                }}
                className={classes.dashboardGridCardValue}
              >
                {displayValue}
              </span>
              <span style={{ fontSize: "1.5rem" }}>
                {unit} {aggregationUnit && "/d"}
              </span>
            </>
          )}
        </div>

        {aggregationUnit && (
          <div style={{ display: "flex" }}>
            <span
              style={{
                fontSize: "0.875rem",
                padding: "0 0.5rem",
                color: "grey",
              }}
            >
              {formattedValue} {unit}
            </span>
          </div>
        )}

        <span
          style={{ fontSize: "0.875rem", letterSpacing: "1px", color: "grey" }}
        >
          {description}
        </span>
      </div>
    </div>
  );
};
