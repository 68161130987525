import { ArrowRight } from "@carbon/icons-react";
import { Button, InlineLoading } from "@carbon/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { PartnerCustomerDto, customersApi } from "./api/customersApi";
import "./customerList.scss";

export const CustomerList = () => {
  const [customers, setCustomers] = useState<PartnerCustomerDto[] | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    getCustomer();
  }, []);

  const getCustomer = async () => {
    try {
      const data = await customersApi.getPartnerCustomers();
      setCustomers(data.customers);
    } catch (e) {
      setCustomers(null);
    }
  };

  const renderCustomerList = () => {
    if (!customers) {
      return <InlineLoading />;
    }
    return customers.map((customer) => (
      <div className="customer-grid-item" key={customer.id}>
        <div className="customer-name-icon">
          <p className="customer-name cds-type--fluid-heading-04">
            {customer.name}
          </p>

          <img
            className="customer-icon"
            src={customer.logoUrl}
            alt="customer logo"
          />
        </div>

        <div className="customer-item-button-group">
          <Button
            kind="ghost"
            renderIcon={ArrowRight}
            onClick={() => navigate(`${customer.id}/details`)}
          >
            More Details
          </Button>
        </div>
      </div>
    ));
  };

  return (
    <div className="customer-list-container">
      <div className="customer-list-grid">{renderCustomerList()}</div>
    </div>
  );
};
