// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { AddFilled, User, UserCertification } from "@carbon/icons-react";
import {
  Button,
  DataTable,
  Loading,
  Modal,
  OverflowMenu,
  OverflowMenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from "@carbon/react";
import { useState } from "react";
import { useNavigate } from "react-router";
import {
  PartnerCustomerDetailsDto,
  UpdateCustomerPrimaryUserDto,
  customersApi,
} from "./api/customersApi";
import "./customerUsersTable.scss";

const headers = [
  {
    key: "displayName",
    header: "Name",
  },
  {
    key: "email",
    header: "Email",
  },
  {
    key: "position",
    header: "Position",
  },
];

interface CustomerUsersTableProps {
  data: PartnerCustomerDetailsDto;
  refreshCustomerUsers: any;
}

export const CustomerUsersTable = ({
  data,
  refreshCustomerUsers,
}: CustomerUsersTableProps) => {
  const [deleteModalOpened, setDeleteModelOpened] = useState<boolean>(false);
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const navigate = useNavigate();

  if (!data) {
    return <Loading withOverlay="true" />;
  }

  const users = data.users;

  if (data.users.length == 0) {
    return <div className="empty-user-list">Users not found</div>;
  }

  const updatePrimaryUser = async (userId: string) => {
    const updatePrimaryUserDto: UpdateCustomerPrimaryUserDto = {
      userId: userId,
    };

    const response = await customersApi.updateCustomerPrimaryUser(
      data.customer.id,
      updatePrimaryUserDto
    );

    if (response) {
      refreshCustomerUsers();
    }
  };

  const handleDeleteAction = (userId: string) => {
    setDeleteModelOpened(true);
    setUserIdToDelete(userId);
  };

  const handleDeleteUser = async () => {
    const deleteUserResponse = await customersApi.deletePartnerCustomerUser(
      data.customer.id,
      userIdToDelete
    );
    if (deleteUserResponse) {
      refreshCustomerUsers();
    }

    setDeleteModelOpened(false);
  };

  return (
    <Stack className="customer-user-table-container">
      <p className="cds--type-fluid-heading-04">
        {data.customer.name}&apos;s Users
      </p>
      <DataTable rows={users} headers={headers} className="customer-user-table">
        {({
          rows,
          headers,
          getTableProps,
          getToolbarProps,
          getHeaderProps,
        }) => (
          <TableContainer>
            <TableToolbar
              {...getToolbarProps()}
              aria-label="data table toolbar"
            >
              <TableToolbarContent>
                <Button
                  onClick={() =>
                    navigate(
                      `/customers/${data.customer.id}/new-customer-user`,
                      { state: { customerName: data.customer.name } }
                    )
                  }
                  renderIcon={AddFilled}
                >
                  Add User
                </Button>
              </TableToolbarContent>
            </TableToolbar>

            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  <TableHeader></TableHeader>
                  {headers.map((header) => (
                    <TableHeader
                      key={header.key}
                      {...getHeaderProps({ header, isSortable: true })}
                    >
                      {header.header}
                    </TableHeader>
                  ))}
                  <TableHeader></TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {row.id == data.primaryAdminUserId ? (
                        <UserCertification size={32} />
                      ) : (
                        <User size={32} />
                      )}
                    </TableCell>
                    {row.cells.map((cell) => {
                      return <TableCell key={cell.id}>{cell.value}</TableCell>;
                    })}

                    <TableCell className="cds--table-column-menu">
                      {row.id == data.primaryAdminUserId ? (
                        <></>
                      ) : (
                        <OverflowMenu flipped>
                          <OverflowMenuItem
                            itemText="Make Primary"
                            onClick={() => {
                              updatePrimaryUser(row.id);
                            }}
                          />
                          <OverflowMenuItem
                            isDelete
                            itemText="Delete"
                            onClick={() => handleDeleteAction(row.id)}
                          />
                        </OverflowMenu>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Modal
              open={deleteModalOpened}
              onRequestSubmit={() => {
                handleDeleteUser();
              }}
              onSecondarySubmit={() => setDeleteModelOpened(false)}
              onRequestClose={() => setDeleteModelOpened(false)}
              danger
              modalHeading="By deleting the user all data related to the user will be lost"
              modalLabel="Are you sure you want to delete the user?"
              primaryButtonText="Delete"
              secondaryButtonText="Cancel"
            />
          </TableContainer>
        )}
      </DataTable>
    </Stack>
  );
};
