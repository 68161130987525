import { apiClient } from "../../components/api/apiClient";
import { AssetDto } from "../assets/assetsApi";

export interface AssetGroupListDto {
  assetGroups: AssetGroupDto[];
  sites: AssetGroupSiteDto[];
  assetGroupTypes: AssetGroupTypeDto[];
}

export interface AssetGroupDto {
  id: string;
  name: string;
  assetTypeId: string;
  siteId: string;
  telemetryId: string;
  iconUrl: string;
  assetGroupType: AssetGroupTypeDto;
  assets: AssetDto[];
}

export interface AssetGroupTypeDto {
  description: string;
  iconUrl: string;
  mobilityType: string;
  name: string;
  id: string;
}

export interface AssetGroupSiteDto {
  id: string;
  displayName: string;
  fullAddress: string;
  geoLocation: {
    latitude: number;
    longitude: number;
  };
}

export const assetGroupsApi = {
  async getAssetGroupList(): Promise<AssetGroupListDto> {
    const response = await apiClient.get("/asset-group");
    return response.data;
  },

  async getAssetGroupById(assetId: string): Promise<any> {
    const response = await apiClient.get(`/asset-groups/${assetId}`);
    return response.data;
  },
};
