export const getTagUnit = (tag: string) => {
  const tagId = tag.toLowerCase();
  if (
    tagId.includes("tc") ||
    tagId.includes("tt") ||
    tagId.includes("temperature")
  ) {
    return "°C";
  } else if (tagId.includes("pt") || tagId.includes("pressure")) {
    return "psi";
  } else if (tagId.includes("flow")) {
    return "kg";
  } else {
    return "";
  }
};

export const formatTagValue = (value: string, decimalPoints: number) => {
  const numericValue = parseFloat(value);

  if (!isNaN(numericValue)) {
    return Number.isInteger(numericValue)
      ? value
      : numericValue.toFixed(decimalPoints);
  }
  return "N/A";
};

export const hexToBitIndex = (hexNumber: string): number | null => {
  const hexBigInt = BigInt(hexNumber);
  const binaryString = hexBigInt.toString(2);

  const reversedBinary = binaryString.split("").reverse().join("");
  const bitIndex = reversedBinary.indexOf("1");
  return bitIndex !== -1 ? bitIndex : null;
};

export const formatDashboardNumber = (numberToFormat: number) => {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    useGrouping: false,
  }).format(numberToFormat);
};
