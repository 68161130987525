import { create } from "zustand";
import { RoleDto } from "./usersApi";

interface RoleState {
  roles: RoleDto[] | null;
  setRoles: (roles: RoleDto[]) => void;
}

export const useRoleStore = create<RoleState>()((set) => ({
  roles: null,
  setRoles: (roles: RoleDto[]) => set({ roles }),
}));
