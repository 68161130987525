import { useEffect, useRef, useState } from "react";
import { Image, Layer, Stage } from "react-konva";
import useImage from "use-image";
import { DiagramRectTag } from "../../../components/monitoring-dashboards/process-flow-diagrams/DiagramRectTag";
import { DiagramStateTag } from "../../../components/monitoring-dashboards/process-flow-diagrams/DiagramStateTag";
import {
  AssetGroupPfdDiagramDashboardDisplaySettingsDto,
  AssetGroupTelemetryDataDto,
  TelemetryPoint,
  TelemetryPointType,
} from "../dtos/asset-group-pfd.dto";

interface AssetPfdDiagramProps {
  displaySettings: AssetGroupPfdDiagramDashboardDisplaySettingsDto;
  telemetryTagsData?: AssetGroupTelemetryDataDto[];
  isDarkMode: boolean;
}

export const AssetGroupPfdDiagram = ({
  displaySettings,
  telemetryTagsData,
  isDarkMode,
}: AssetPfdDiagramProps) => {
  const [image] = useImage(
    `${
      isDarkMode
        ? displaySettings.diagramSettings.fileUrl
        : displaySettings.diagramSettings.fileUrl.replace("_dark", "")
    }`
  );
  const [stageSize, setStageSize] = useState({
    width: 300,
    height: 0,
  });
  const parentRef = useRef<HTMLDivElement>(null);

  const [stage, setStage] = useState({
    scale: 1,
    x: 0,
    y: 0,
  });
  const [tagRadius, setTagRadius] = useState(0);

  useEffect(() => {
    setTagRadius(displaySettings.diagramSettings.tagRadius);
  }, [displaySettings]);

  const handleResize = () => {
    const parentWidth = parentRef.current?.clientWidth || 600;
    const parentHeight = parentRef.current?.clientHeight || 400;

    const aspectRatio = image ? image.width / image.height : 1;
    let newWidth = parentWidth;
    let newHeight = parentHeight;

    if (parentWidth / parentHeight > aspectRatio) {
      newWidth = parentHeight * aspectRatio;
    } else {
      newHeight = parentWidth / aspectRatio;
    }

    setStageSize({ width: newWidth, height: newHeight });

    setStage({
      scale: 1,
      x: 0,
      y: 0,
    });
  };

  useEffect(() => {
    let resizeTimeout: any;

    const handleResizeWithTimeout = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        handleResize();
      }, 10);
    };

    handleResize();

    window.addEventListener("resize", handleResizeWithTimeout);

    return () => {
      clearTimeout(resizeTimeout);
      window.removeEventListener("resize", handleResizeWithTimeout);
    };
  }, [image]);

  useEffect(() => {
    if (parentRef.current?.clientWidth) {
      handleResize();
    }
  }, [parentRef.current?.clientWidth]);

  const scale = () => {
    if (image) {
      return stageSize.width / image.width;
    }
    return 0;
  };

  const handleWheel = (e: any) => {
    e.evt.preventDefault();

    const scaleBy = 1.02;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };

    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    setStage({
      scale: newScale,
      x: (stage.getPointerPosition().x / newScale - mousePointTo.x) * newScale,
      y: (stage.getPointerPosition().y / newScale - mousePointTo.y) * newScale,
    });
  };

  const handleClick = (e: any) => {
    e.evt.preventDefault();

    const stage = e.target.getStage();
    const mousePointTo = {
      x: stage.getPointerPosition().x,
      y: stage.getPointerPosition().y,
    };

    return mousePointTo;
  };

  const getTagValue = (tagId: string, assetName: string) => {
    const telemetryId = displaySettings.telemetryIds[assetName];

    if (!telemetryId) {
      return "-";
    }

    const telemetryData = telemetryTagsData?.find(
      (data) => data.telemetryId === telemetryId
    );

    if (telemetryData) {
      const tag = telemetryData.data.find((tag) => tag.tagId === tagId);

      return tag ? tag.tagValue : "-";
    }
    return "-";
  };

  return (
    <div
      ref={parentRef}
      style={{
        display: "flex",
        width: "calc(100% - 16px)",
        height: "calc(100% - 16px)",
        margin: "0 8px",
        justifyContent: "flex-start",
        alignContent: "center",
      }}
    >
      <Stage
        width={stageSize.width}
        height={stageSize.height}
        scaleX={stage.scale}
        scaleY={stage.scale}
        x={stage.x}
        y={stage.y}
        onWheel={handleWheel}
        draggable
        onClick={handleClick}
      >
        <Layer>
          <Image
            image={image}
            width={stageSize.width}
            height={stageSize.height}
          />
        </Layer>
        <Layer>
          {displaySettings.telemetryPoints.map((point: TelemetryPoint) => {
            const tagValue = getTagValue(point.tagId, point.assetName);

            return point.type === TelemetryPointType.Boolean ||
              point.type === TelemetryPointType.Enum ? (
              <DiagramStateTag
                key={`${point.tagId}-${point.assetName}-${point.x}-${point.y}`}
                tagKey={point.tagId}
                tagName={point.name}
                x={point.x * scale()}
                y={point.y * scale()}
                radius={tagRadius * scale()}
                tagValue={String(tagValue) === "true"}
                warningStates={displaySettings.warningStates}
                darkMode={isDarkMode}
              />
            ) : (
              <DiagramRectTag
                key={`${point.tagId}-${point.assetName}-${point.x}-${point.y}`}
                tagKey={point.tagId}
                x={point.x * scale()}
                y={point.y * scale()}
                radius={tagRadius * scale()}
                backgroundColor={"#191818"}
                tagValue={tagValue}
                telemetryId={displaySettings.telemetryIds[point.assetName]}
                isRateValue={point.isRateOfChange as any}
              />
            );
          })}
        </Layer>
      </Stage>
    </div>
  );
};
