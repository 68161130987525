import { InlineLoading, Stack } from "@carbon/react";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { CustomBreadcrumb } from "../../common/CustomBreadcrumb";

import { formatToLocaleDateTime } from "../../common/dates";
import { AssetGroupDashboard } from "./asset-group-dashboards/AssetGroupDashboard";
import {
  ASSET_GROUP_TELEMETRY_POLLING_RATE_MS,
  assetGroupTelemetryApi,
  AssetGroupTelemetryResponseDto,
} from "./assetGroupTelemetryApi";
import { AssetGroupTelemetryDataResponse } from "./dtos/asset-group-pfd.dto";

export const AssetGroupDetailsPage = () => {
  const { assetGroupId } = useParams();
  const [assetGroupTelemetryData, setAssetGroupTelemetryData] =
    useState<AssetGroupTelemetryDataResponse | null>(null);
  const [assetGroupDetails, setAssetGroupDetails] =
    useState<AssetGroupTelemetryResponseDto | null>(null);
  const isFetchingRef = useRef(false);

  useEffect(() => {
    if (assetGroupId) {
      getAssetGroupTelemetry(assetGroupId);
    }
  }, [assetGroupId]);

  useEffect(() => {
    if (assetGroupDetails) {
      getAssetGroupTelemetryValues();
    }

    const intervalId = setInterval(
      getAssetGroupTelemetryValues,
      ASSET_GROUP_TELEMETRY_POLLING_RATE_MS
    );
    return () => clearInterval(intervalId);
  }, [assetGroupDetails]);

  const getAssetGroupTelemetry = async (assetGroupId: string) => {
    try {
      const assetGroupTelemetryResponse =
        await assetGroupTelemetryApi.getAssetGroupTelemetry(assetGroupId);
      setAssetGroupDetails(assetGroupTelemetryResponse);
    } catch (e) {
      setAssetGroupDetails(null);
    }
  };

  const getAssetGroupTelemetryValues = async () => {
    if (!assetGroupDetails || isFetchingRef.current) return;

    try {
      isFetchingRef.current = true;
      const assetGroupTelemetryValuesResponse =
        await assetGroupTelemetryApi.getAssetGroupTelemetryValues(
          assetGroupDetails.assetGroupTelemetry.id
        );
      setAssetGroupTelemetryData(assetGroupTelemetryValuesResponse);
    } catch (e) {
      isFetchingRef.current = false;
    } finally {
      isFetchingRef.current = false;
    }
  };

  const breadcrumbItems = [
    { label: "Monitoring", href: "/monitoring" },
    {
      label: assetGroupDetails
        ? assetGroupDetails.assetGroup.name
        : "Loading...",
      showLoading: !assetGroupDetails || !assetGroupTelemetryData,
      isCurrentPage: true,
    },
  ];

  function renderStatusTags() {
    if (assetGroupTelemetryData) {
      return (
        <div className="status-container-scrollable" style={{ width: "500px" }}>
          {assetGroupDetails?.assets.map((asset, index) => {
            const telemetryData =
              assetGroupTelemetryData.assetGroupTelemetryData.find(
                (data) => data.telemetryId === asset.telemetryId
              );

            const lastUpdatedAt = telemetryData
              ? formatToLocaleDateTime(telemetryData.lastUpdateAt)
              : "-";

            return (
              <div key={index} className="status">
                <span className="status-type">{asset.name}</span>
                <span
                  className="status-condition cds--type-legal-01"
                  style={{
                    color: "black",
                  }}
                >
                  {lastUpdatedAt}
                </span>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <InlineLoading />;
    }
  }

  return (
    <div style={{ height: "100%" }}>
      <CustomBreadcrumb items={breadcrumbItems} />

      {assetGroupDetails ? (
        <div style={{ height: "100%" }}>
          <Stack orientation="horizontal" className="equipment-details-page">
            <Stack orientation="horizontal" gap="0">
              <div style={{ width: "150px" }}>
                <img src="/equipment_default.png" alt="Asset Group Icon" />
              </div>
              <Stack className="asset-details">
                <div className="asset-name">
                  {assetGroupDetails?.assetGroup.name}
                </div>
              </Stack>
            </Stack>

            {renderStatusTags()}
          </Stack>

          <AssetGroupDashboard
            assetGroupTelemetry={assetGroupDetails}
            assetGroupTelemetryData={assetGroupTelemetryData}
            assets={assetGroupDetails.assets}
            dashboards={assetGroupDetails.dashboards}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
