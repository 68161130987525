import { useEffect, useState } from "react";
import { Circle, Group, Text } from "react-konva";

export interface DiagramCircleTagProps {
  radius: number;
  backgroundColor: string;
  onClick: () => void;
  x: number;
  y: number;
  tagKey: string;
  tagValue: string;
}

export const DiagramCircleTag = ({
  radius,
  backgroundColor,
  onClick,
  x,
  y,
  tagKey,
  tagValue,
}: DiagramCircleTagProps) => {
  const [tempBackgroundColor, setTempBackgroundColor] = useState<string>("");

  useEffect(() => {
    setTempBackgroundColor("green");
    const timeoutId = setTimeout(() => {
      setTempBackgroundColor(backgroundColor);
    }, 150);
    return () => clearTimeout(timeoutId);
  }, [tagValue, backgroundColor]);

  return (
    <Group key={tagKey} x={x} y={y}>
      <Circle radius={radius} fill={tempBackgroundColor} onClick={onClick} />
      <Text
        text={tagKey.replace(/([a-zA-Z]+)(\d+)/, "$1\n$2")}
        height={radius * 2}
        width={radius * 2}
        x={-radius}
        y={-radius}
        align="center"
        verticalAlign="middle"
        fill="white"
        fontSize={radius * 0.7}
        onClick={onClick}
      />
    </Group>
  );
};
