export enum TelemetryDashboardType {
  THINGSBOARD_EMBEDDED = 1,
  PROCESS_FLOW_DIAGRAM = 2,
  P_AND_ID_DIAGRAM = 3,
  EXTERNAL_DASHBOARD = 4,
  METRICS_DASHBOARD = 5,
  DIAGNOSTIC_DASHBOARD = 6,
  ASSET_GROUP_PROCESS_FLOW_DIAGRAM = 10,
  UNKNOWN = -1,
}

export const TelemetryDashboardTypeLabelMap = {
  [TelemetryDashboardType.THINGSBOARD_EMBEDDED]: "Dashboard",
  [TelemetryDashboardType.PROCESS_FLOW_DIAGRAM]: "PFD",
  [TelemetryDashboardType.P_AND_ID_DIAGRAM]: "P&ID",
  [TelemetryDashboardType.EXTERNAL_DASHBOARD]: "External Dashboard",
  [TelemetryDashboardType.METRICS_DASHBOARD]: "Telemetry Dashboard",
  [TelemetryDashboardType.DIAGNOSTIC_DASHBOARD]: "Diagnostic Dashboard",
  [TelemetryDashboardType.ASSET_GROUP_PROCESS_FLOW_DIAGRAM]: "Asset Group PFD",
  [TelemetryDashboardType.UNKNOWN]: "Unknown",
};

export const isTelemetryDashboardType = (
  type: number,
  targetType: TelemetryDashboardType
): boolean => {
  return type === targetType;
};

export const telemetryDashboardTypeChecks = {
  isThingsboardEmbedded: (type: number): boolean =>
    isTelemetryDashboardType(type, TelemetryDashboardType.THINGSBOARD_EMBEDDED),
  isProcessFlowDiagram: (type: number): boolean =>
    isTelemetryDashboardType(type, TelemetryDashboardType.PROCESS_FLOW_DIAGRAM),
  isPAndIDDiagram: (type: number): boolean =>
    isTelemetryDashboardType(type, TelemetryDashboardType.P_AND_ID_DIAGRAM),
  isExternalDashboard: (type: number): boolean =>
    isTelemetryDashboardType(type, TelemetryDashboardType.EXTERNAL_DASHBOARD),
  isInternalDashboard: (type: number): boolean =>
    isTelemetryDashboardType(type, TelemetryDashboardType.METRICS_DASHBOARD),
  isDiagnosticDashboard: (type: number): boolean =>
    isTelemetryDashboardType(type, TelemetryDashboardType.DIAGNOSTIC_DASHBOARD),
  isUnknown: (type: number): boolean =>
    isTelemetryDashboardType(type, TelemetryDashboardType.UNKNOWN),
};
